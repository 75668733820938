import { Flex, Heading, ListItem, UnorderedList } from "@chakra-ui/react";

export default function UserData({user, ...props}) {

	return (
		<Flex
			{...props}
			flexDir='column'
			marginY='12px'
		>
			<Heading>User infomations</Heading>
			<UnorderedList
				marginLeft='32px'
			>
				<ListItem>Intra ID: {user.intraUser.intraID}</ListItem>
				<ListItem>Creation Date: {new Intl.DateTimeFormat('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(user.createdAt))}</ListItem>
				<ListItem>Last Update Date: {new Intl.DateTimeFormat('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(user.lastUpdatedAt))}</ListItem>
			</UnorderedList>
		</Flex>
	);
}
