import { useState, useEffect, useContext } from 'react'
import config from '../config';
import { UserContext } from '../context/UserContext';
import useWebSocket from 'react-use-websocket';

export default function useUserOrder() {

	const {me} = useContext(UserContext)
	const [orders, setOrders] = useState([]);

	const sortOrders = (listOrders) => {
		function getRating(a)
		{
			if (a.state === 2)
				return (5)
			if (a.state === 1)
				return (4);
			if (a.state === 3)
				return (6);
			if (a.state === 4)
				return (1);
			return (0);
		}
		setOrders(listOrders.filter(order => !order.shift.state || order.shift.state === 1).sort((a, b) => {
			return (getRating(b) - getRating(a))
		}))
	}

	const getOrders = async () => {
		fetch(`${config.apiUrl}/api/me/orders`, {
			credentials: 'include'
		})
		.then(async (res) => {
			if (res.ok)
			{
				const	data = await res.json();
				sortOrders(data);
			}
		})
	}


	useWebSocket(`${config.wsUrl}/api/me/notify`, {
		onOpen: () => {
		  console.log('WebSocket connection established.');
		},
		onMessage: (event) =>  {
			const msg = JSON.parse(event.data);
			if (msg.type === 'new')
				sortOrders([msg.new, ...orders]);
			if (msg.type === 'delete')
				sortOrders([...orders.filter(order => order._id !== msg.delete)]);
			if (msg.type === 'patch')
				sortOrders([...orders].map(order => {
					if (order._id === msg.patch.order._id){
						console.log(msg.patch.order);
						return msg.patch.order;
					}
					else if (msg.patch.finished && order._id === msg.patch.finished.order._id)
						return msg.patch.finished.order;
					return order;
				}));
	 	},
		shouldReconnect: (closeEvent) => true,
		reconnectAttempts: 20,
		reconnectInterval: 10000,
	});

	useEffect(() => {
		if (me)
			getOrders()
		// eslint-disable-next-line
	}, [me])

	return {
		orders
	}
}
