import {
	Flex,
	IconButton,
	Text,
  } from '@chakra-ui/react'

export default function UserPagination({currentPage, maxPages, setPage, previousPage, nextPage, ...props}) {

	return (
		<Flex
			{...props}
			alignItems='center'
			justifyContent='center'
		>
			<IconButton
				isDisabled={currentPage === 1}
				icon={<i className="fa-solid fa-angle-left"></i>}
				onClick={previousPage}
			/>
			<Text
				color='gray'
				marginInline='12px'
			>
				{currentPage} / {maxPages}
			</Text>
			<IconButton
				isDisabled={currentPage === maxPages}
				icon={<i className="fa-solid fa-angle-right"></i>}
				onClick={nextPage}
			/>
		</Flex>
	);
}