import { Button, Card, CardBody, CardFooter, IconButton, Input } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import { GroupContext } from '../../context/GroupContext';

export default function ShiftCardEdit({updateShift, defaultShift, deleteShift, ...props}) {

	const [name, setName] = useState(defaultShift.name);
	const [isAdmin, setIsAdmin] = useState(false);

	const {me} = useContext(UserContext);
	const {group} = useContext(GroupContext);

	useEffect(() => {
		setIsAdmin((group.admins.find((admin) => admin._id === me._id) || group.owner._id === me._id));
	}, [group, me]);

	const renameShift = () => {
		updateShift({name, _id: defaultShift._id});
	}

	const patchShift = (state) => {
		if (state === 2 && defaultShift._id === localStorage.getItem('shift'))
			localStorage.removeItem('shift');
		updateShift({_id: defaultShift._id, state});
	}

	const joinShift = () => {
		localStorage.setItem('shift', defaultShift._id);
		window.location.href = `/groups/${group.name}/shifts/${defaultShift._id}/orders`;
	}

	return (
		<Card
			marginY='8px'
			{...props}
		>
			<CardBody
				display='flex'
				alignItems='center'
				padding='8px'
			>
				<Input
					placeholder='Shift name'
					value={name}
					isDisabled={!isAdmin || defaultShift.state > 0}
					onChange={(e) => setName(e.target.value)}
				/>
				{defaultShift.name !== name && isAdmin && defaultShift.state === 0 && name.length > 0 &&
					<IconButton
						marginLeft='8px' icon={<i className="fa-solid fa-check"></i>}
						onClick={() => renameShift()}
					/>
				}
			</CardBody>
			<CardFooter display='flex' alignItems='center' justifyContent='center' padding='8px'>
				{defaultShift.state === 0 && isAdmin &&
					<Button
						colorScheme='green'
						flex='2'
						leftIcon={<i className="fa-solid fa-play"></i>}
						onClick={() => patchShift(1)}
					>
						Start
					</Button>
				}
				{defaultShift.state === 0 && !isAdmin &&
					<Button
						flex='99'
						isDisabled
						colorScheme='orange'
						leftIcon={<i className="fa-solid fa-hourglass-start"></i>}
					>
						Wait for start
					</Button>
				}
				{defaultShift.state === 1 &&
					<Button
						flex={isAdmin ? '2' : '99'}
						colorScheme='green'
						leftIcon={<i className="fa-solid fa-arrow-right-to-bracket"></i>}
						onClick={() => joinShift()}
					>
						Join
					</Button>
				}
				{ isAdmin &&
					<Button
						flex='1'
						marginInline='8px'
						colorScheme='gray'
						leftIcon={<i className="fa-solid fa-box-archive"></i>}
						onClick={() => patchShift(2)}
					>
						Archive
					</Button>
				}
				{ isAdmin &&
					<Button
					flex='1'
						isDisabled={defaultShift.state === 1}
						leftIcon={<i className="fa-solid fa-trash"></i>}
						colorScheme='red'
						onClick={() => deleteShift(defaultShift)}
					>
						Delete
					</Button>
				}
			</CardFooter>
		</Card>
	);
}
