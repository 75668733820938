import AdminTemplate from "../../templates/AdminTemplate";

export default function Home() {

	return (
		<AdminTemplate>
			
		</AdminTemplate>
	);
}
