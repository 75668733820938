import { Flex, IconButton, Input } from "@chakra-ui/react";


export default function CheckoutItemQuantity({quantity, setQuantity, ...props})
{

	return (
		<Flex
			{...props}
		>
			<IconButton
				onClick={() => setQuantity(quantity - 1)}
				isDisabled={quantity <= 1}
				aria-label='Quantity Remove'
				icon={<i className="fa-solid fa-minus"></i>}
			/>
			<Input
				isReadOnly
				width='15vw'
				mx='1vw'
				type='number'
				value={quantity}
				name="quantity"
			/>
			<IconButton
				onClick={() => setQuantity(quantity + 1)}
				aria-label='Quantity Add'
				icon={<i className="fa-solid fa-plus"></i>}
			/>
		</Flex>
	);

}