import { Avatar, Flex, Heading, Text } from "@chakra-ui/react";


export default function UserTableProfileCell(params)
{
	return (
		<Flex alignItems='center' height='100%'>
			<Avatar
				size='sm'
				name={params.value.login}
				src={params.value.src}
				position='relative'
			/>
			<Heading
				marginX='8px'
				size='sm'
			>
				{params.value.login}
			</Heading>
			{params.value.admin === 1 &&
				<Text color='gold'>
					<i className="fa-regular fa-star"></i>
				</Text>
			}
			{params.value.admin === 2 &&
				<Text color='gold'>
					<i className="fa-solid fa-crown"></i>
				</Text>
			}
		</Flex>
	);

}