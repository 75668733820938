import { Card, Image } from "@chakra-ui/react";

export default function InfoCard({ orderHide, ...props }) {

	return (
		<Card
			{...props}
			display='flex'
			padding='24px'
			borderRadius='1.5vw'
			justifyContent='space-around'
			alignItems='center'
			overflow='hidden'
			flexDir='row'
		>
			<Image src="/QRCode.png" height='128px'/>
		</Card>
	);
}