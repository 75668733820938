import {
	InputGroup,
	InputLeftElement,
	Input,
  } from '@chakra-ui/react'

export default function UserSearchBar({onSearch, search, ...props}) {

	const handleChange = (event) => {
		onSearch(event.target.value)
	}

	return (
		<InputGroup
			{...props}
		>
			<InputLeftElement pointerEvents='none'>
				<i className="fa-solid fa-magnifying-glass"></i>
			</InputLeftElement>
			<Input
				onChange={handleChange}
				name='login'
				type='text'
				placeholder='Search user'
				defaultValue={search}
			/>
		</InputGroup>
	);
}