import { useState } from 'react'

export default function useExpand()
{
	const [expandIndex, setExpandIndex] = useState([])
	const [expandOrders, setExpandOrders] = useState([])

	const handleExpand = (indexes, orders) => {
		setExpandIndex(indexes);
		setExpandOrders(indexes.map((index) => (orders[index]._id)));
	}

	const applyExpand = (orders) =>
	{
		var newIndexes = expandOrders.map((id) => (orders.findIndex(order => order._id === id)));
		setExpandIndex(newIndexes);
	}

	return [expandIndex, handleExpand, applyExpand];
}
