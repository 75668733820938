import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import NewProductForm from "./NewProductForm";
import useProduct from "../../hooks/useProduct";
import { useState } from "react";

export default function NewProductModal({isOpen, onClose, onSave, defaultProduct}) {
  
  const { product, isValid, edit } = useProduct(defaultProduct);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const saveProduct = async () => {
    setIsSubmitting(true);
    const res = await onSave(product, product._id);
    setIsSubmitting(false);
    if (res)
      onClose();
  }


	return (
		<Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <NewProductForm
              edit={edit}
              product={product}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} mr='8px' variant='outline'>
              Close
            </Button>
            <Button
              isLoading={isSubmitting}
              colorScheme='green'
              onClick={() => saveProduct()}
              isDisabled={!isValid}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
	);
}