import React from 'react';
import {
    Modal,
    ModalBody,
    ModalContent,
    ModalCloseButton,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Button,
	Text
} from '@chakra-ui/react';

export default function OrderItemDeleteModal({isOpen, onClose, order}) {

    return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
         <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px)'
        />
        <ModalContent>
            <ModalHeader>Delete order #{order.orderNumber} ?</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Text>
                    You are about to delete {order.user.login}'s order.
                    Are you sure?
                </Text>
            </ModalBody>
            <ModalFooter>
                <Button
                    leftIcon={<i className="fa-solid fa-trash"></i>}
                    colorScheme='red' mr={3}
                    onClick={() => order.delete()}
                >
                    Delete
                </Button>
                <Button onClick={onClose}>Close</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
    );
}