import { Flex, Progress} from "@chakra-ui/react";
import { useEffect, useState } from "react";

export default function OrderCardProgress({ order, maxStep, ...props }) {

	const [steps, setSteps] = useState();

	useEffect(() => {
		const stepsArray = new Array(maxStep);
		for (let i = 0; i < maxStep; i++) {
			if (i < order.state)
				stepsArray[i] = 2;
			else if (i === order.state)
				stepsArray[i] = 1;
			else
				stepsArray[i] = 0;
		}
		setSteps(stepsArray);
		// eslint-disable-next-line
	}, [order]);

	if (steps)
	return (
		<Flex
			{...props}
			alignItems='center'
			justifyContent='space-between'
		>
			{steps.map((step, index) => {
				if (step === 2)
					return (
						<Progress
							colorScheme='green'
							size='sm'
							value={100}
							width={`calc((100% / ${maxStep}) * 0.9)`}
							key={index}
						/>
					)
				if (step === 1)
					return (
						<Progress
							colorScheme='green'
							size='sm'
							isIndeterminate
							width={`calc((100% / ${maxStep}) * 0.9)`}
							key={index}
						/>
					)
				return (
					<Progress
						colorScheme='green'
						size='sm'
						value={0}
						width={`calc((100% / ${maxStep}) * 0.9)`}
						key={index}
					/>
				)
			})}
		</Flex>
	);
}