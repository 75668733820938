import { Stack } from "@chakra-ui/react";
import AdminTemplate from "../../templates/AdminTemplate";
import NewDeviceCard from "../../components/Devices/NewDeviceCard";
import DeviceList from "../../components/Devices/DeviceList";
import useDevices from "../../hooks/useDevices";

export default function Devices() {

	const devices = useDevices();

	return (
		<AdminTemplate>
			<Stack
				alignItems='center'
				overflowY='scroll'
				paddingBottom='48px'
			>
				<NewDeviceCard
					width='90vw'
					marginY='16px'
					createDevice={(n, p) => devices.create(n, p)}
				/>
				<DeviceList devices={devices.devices}/>
			</Stack>
		</AdminTemplate>
	);
}
