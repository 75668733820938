import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Text } from "@chakra-ui/react";
import CheckoutItemQuantity from "./CheckoutItemQuantity";
import { useState } from "react";
import CheckoutMenuStep from "./CheckoutMenuStep";


export default function CheckoutMenuDrawer({item, isOpen, onClose, cart, ...props})
{

	const [quantity, setQuantity] = useState(1);
	const [subItems, setSubItems] = useState(new Array(item.categories.length).fill(null));
	const [step, setStep] = useState(0);

	const handleSubItemChange = (subItem) => {
		const newSubItems = [...subItems];
		newSubItems[step] = subItem;
		setSubItems(newSubItems);
	}

	const onAddProduct = () => {
		cart.addItem({
			product: item,
			subItems: subItems.filter(subItem => subItem !== null),
			quantity,
		});
		onClose();
		setSubItems(new Array(item.categories.length).fill(null));
		setStep(0);
	}

	return (
		<Drawer
			isOpen={isOpen}
			placement='bottom'
			onClose={onClose}
		>
			<DrawerOverlay />
			<DrawerContent height='80%'>
				<DrawerCloseButton />
				<DrawerHeader
					borderBottomWidth='1px'
					display='flex'
					alignItems='center'
				>
						<Text opacity='1' marginX='8px'>
							{item.name}
						</Text>
						<CheckoutItemQuantity
							marginLeft='32px'
							quantity={quantity}
							setQuantity={setQuantity}
						/>
				</DrawerHeader>
				<DrawerBody display='flex' flexDir='column' maxHeight='80%' padding={0} >
					<CheckoutMenuStep
						subItem={subItems[step]}
						category={item.categories[step]}
						onChange={(subItem) => handleSubItemChange(subItem)}
					/>
				</DrawerBody>
				<DrawerFooter borderTopWidth='1px'>
					<Flex
						width='100%'
						justifyContent='space-between'
						alignItems='center'
					>
						<Button
							marginX='8px'
							colorScheme="gray"
							isDisabled={step === 0}
							leftIcon={<i className="fa-solid fa-arrow-left"></i>}
							onClick={() => setStep(step - 1)}
						>
							Previous
						</Button>
						<Text
							position='absolute'
							left='50%'
							transform='translateX(-50%)'
						>
							{step + 1} / {item.categories.length}
						</Text>
						{step < item.categories.length - 1 ?
						<Button
							colorScheme="gray"
							rightIcon={<i className="fa-solid fa-arrow-right"></i>}
							onClick={() => setStep(step + 1)}
						>
							Next
						</Button> 
						:<Button
							colorScheme='green'
							leftIcon={<i className="fa-solid fa-cart-shopping"></i>}
							onClick={onAddProduct}
						>
							Add to cart
						</Button>
						}
					</Flex>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);

}