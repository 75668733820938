import {
	AccordionItem,
	useDisclosure,
} from '@chakra-ui/react'
import OrderItemExpand from './OrderItemExpand';
import OrderItemPanel from './OrderItemPanel';
import OrderItemDrawer from './OrderItemDrawer/OrderItemDrawer';

export default function OrderItem({order, ...props})
{

	const { isOpen, onOpen, onClose } = useDisclosure();

	if (order.user)
	return (
		<AccordionItem
			{...props}
		>
			<OrderItemExpand
				order={order}
				openDrawer={onOpen}
			/>
			<OrderItemPanel
				order={order}
			/>
			<OrderItemDrawer
				isOpen={isOpen}
				onClose={onClose}
				order={order}
			/>
		</AccordionItem>
	);

}