import { Flex, FormControl, FormLabel, Heading, IconButton, Input } from "@chakra-ui/react";
import NewMenuCategory from "./NewMenuCategory";


export default function NewMenuForm({
	edit,
	productList,
	menu,
}) {

	return (
		<Flex flexDir='column'>
			<FormControl marginY='8px' isRequired>
				<FormLabel>Menu Name</FormLabel>
				<Input defaultValue={menu ? menu.name : ''} onChange={(e) => edit.setName(e.target.value)} type='text' />
			</FormControl>
			<FormControl marginY='8px' isRequired>
				<FormLabel>Menu Image</FormLabel>
				<Input defaultValue={menu ? menu.imageUrl : ''} onChange={(e) => edit.setImageUrl(e.target.value)} type='text' />
			</FormControl>
			<FormControl marginRight='4px' isRequired>
				<FormLabel>Price</FormLabel>
				<Input defaultValue={menu ? menu.price : 0} onChange={(e) => edit.setPrice(e.target.value)} type='number' />
			</FormControl>
			<Flex width='100%' alignItems='center' justifyContent='space-between'>
				<Heading marginY='8px'>Category</Heading>
				<IconButton
					colorScheme="green"
					icon={<i className="fa-solid fa-plus"></i>}
					onClick={() => edit.addCategory()}
				/>
			</Flex>
			<Flex overflowY='scroll' marginY='8px' flexDir='column' height='50%' >
				{menu.categories.map((category, index) => (
					<NewMenuCategory
						key={index}
						category={category}
						productList={productList}
						handleName={(name) => edit.handleCategoryName(index, name)}
						deleteCategory={() => edit.removeCategory(index)}
						addProduct={(product) => edit.addCategoryProduct(index, product)}
						handleProduct={(productIndex, product) => edit.handleCategoryProduct(index, productIndex, product)}
						removeProduct={(productIndex) => edit.removeCategoryProduct(index, productIndex)}
					/>
				))}
			</Flex>
		</Flex>
	);
}