import { Flex, Heading, Text } from '@chakra-ui/react';
import AdminTemplate from '../../templates/AdminTemplate';
import ProductList from '../../components/ProductList/ProductList';
import MenuList from '../../components/MenuList/MenuList';
import useProducts from '../../hooks/useProducts'
import { useToast } from '@chakra-ui/react'

export default function Products() {

	const toast = useToast();

	const {products, menus, addProduct, deleteProduct, updateProduct} = useProducts(toast);

	return (
		<AdminTemplate>
			<Flex width='100%' justifyContent='space-between' alignItems='center' minHeight='64px' paddingX='5%'>
				<Heading size='sm'>Products</Heading>
				<Text>{products.length + menus.length} items</Text>
			</Flex>
			<Flex flexDir='column' width='100%' alignItems='center'>

				<ProductList
					maxHeight='35vh' overflowY='scroll'
					products={products}
					addProduct={addProduct}
					deleteProduct={deleteProduct}
					updateProduct={updateProduct}
				/>
				<MenuList
					maxHeight='35vh' overflowY='scroll' marginTop='32px'
					menus={menus}
					productList={products}
					addMenu={addProduct}
					deleteMenu={deleteProduct}
					updateMenu={updateProduct}
				/>
			</Flex>
		</AdminTemplate>
	);
}
