import { Center, Spinner, Stack } from '@chakra-ui/react';
import ProfileHeader from '../components/ProfileHeader/ProfileHeader';
import OrderList from '../components/OrderList/OrderList';
import { UserContext } from '../context/UserContext';
import { useContext } from 'react';


export default function Profile() {

	const { me } = useContext(UserContext);

	if (me && me.type === 'user')
		return (
			<Stack width='100vw' height='100vh' justifyContent='space-between' overflow='hidden'>
				<ProfileHeader
					marginBottom='16px'
				/>
				<OrderList paddingY='16px'/>
			</Stack>
		);
	return (
		<Center width="100vw" height='100vh'>
			<Spinner />
		</Center>
	)
}
