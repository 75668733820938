import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay } from "@chakra-ui/react";
import OrderItemDrawerHeader from "./OrderItemDrawerHeader";
import OrderItemDrawerFooter from "./OrderItemDrawerFooter";
import OrderItemList from "../OrderItemList";


export default function OrderItemDrawer({isOpen, onClose, order})
{
	return (
		<Drawer
			isOpen={isOpen}
			placement='bottom'
			onClose={onClose}
		>
			<DrawerOverlay />
			<DrawerContent height='80%'>
				<DrawerCloseButton />
				<OrderItemDrawerHeader order={order}/>
				<DrawerBody display='flex' flexDir='column' maxHeight='80%' overflowY='scroll'>
					<OrderItemList order={order}/>
				</DrawerBody>
				<OrderItemDrawerFooter onClose={onClose} order={order}/>
			</DrawerContent>
		</Drawer>
	);

}