import { Flex, useDisclosure, Center, Spinner } from '@chakra-ui/react';
import Sidebar from '../components/Sidebar/Sidebar';
import '../App.css'
import Header from '../components/Header/Header';
import { useContext } from 'react';
import { GroupContext } from '../context/GroupContext';

export default function AdminTemplate({children, ...props}) {

	const { isOpen, onOpen, onClose } = useDisclosure();

	const { group, shift } = useContext(GroupContext)

	if (group && undefined !== shift)
		return (
			<div className='App'>
				<Header openMenu={onOpen}/>
				<Sidebar isOpen={isOpen} onClose={onClose} />
				<Flex
					flexDir='column'
					justifyContent='flex-start'
					width='100%' height='calc(100% - 72px)'
					{...props}
				>
					{children}
				</Flex>
			</div>
		);
	else
		return (
			<Center width='100vw' height='100vh' >
				<Spinner size='xl' />
			</Center>	
		)
}
