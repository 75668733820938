import {
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Flex,
	Heading,
	Text,
	Stack,
	FormControl,
	FormLabel,
	Input,
	FormHelperText,
	IconButton,
	Button,
	useDisclosure,
  } from '@chakra-ui/react'
import DeviceConnectModal from './DeviceConnectModal';
import DeviceRefreshModal from './DeviceRefreshModal';
import DeviceDeleteModal from './DeviceDeleteModal';
import useAlert from '../../hooks/useAlert';

export default function DeviceListItem({device, ...props}) {

		const connectModal = useDisclosure();
		const refreshModal = useDisclosure();
		const deleteModal = useDisclosure();


		const { alertSuccess } = useAlert();

		const refreshDevice = () => {
			if (device.token)
			{
				navigator.clipboard.writeText(device.token);
				alertSuccess('Token copied to clipboard');
			}
			else
				refreshModal.onOpen();
		}

		return (
			<AccordionItem>
				<AccordionButton>
				  	<Flex
					  alignItems='center'
						justifyContent='space-between'
						width='100%'
					>
					<Flex
						alignItems='center'
					>
						<Heading size='md'>{device.name}</Heading>
						<Text marginInline='8px'>{device.permission === 'member' ? 'Member' : 'Admin'}</Text>
					</Flex>
					<Flex
						alignItems='center'
					>
						<Button
							as='a'
							size='sm'
							mx='8px'
							colorScheme='green'
							rightIcon={<i className="fa-solid fa-arrow-right-to-bracket"></i>}
							onClick={(e) => {
								e.stopPropagation()
								connectModal.onOpen()
							}}
						>
							Connect
						</Button>
				  		<AccordionIcon />
					</Flex>
				  </Flex>
				  <DeviceConnectModal isOpen={connectModal.isOpen} onClose={() => connectModal.onClose()} device={device} />
				</AccordionButton>
			  <AccordionPanel pb={4}>
				<Stack>
					<Stack>
						<FormControl>
							<FormLabel>Device Token</FormLabel>
							<Flex>
								<Input
									type={device.token ? 'text' : 'password'}
									value={device.token ? device.token : 'ft_waitlistft_waitlistft_waitlistft_waitlistft_waitlistft_waitlistft_waitlistft_waitlistft_waitlistft_waitlistft_waitlist'}
									isReadOnly
								/>
								<IconButton
									icon={device.token ? <i className="fa-solid fa-copy"></i> : <i className="fa-solid fa-sync"></i>}
									colorScheme={device.token ? 'green' : 'blue'}
									variant='outline'
									aria-label='Refresh token'
									marginLeft='8px'
									onClick={() => refreshDevice()}
								/>
								<DeviceRefreshModal
									isOpen={refreshModal.isOpen}
									onClose={() => refreshModal.onClose()}
									device={device}
								/>
							</Flex>
							<FormHelperText>
								Device token is used to authenticate the device. Generate a new token will invalidate the previous one.
							</FormHelperText>
						</FormControl>
						<Text my='16px' opacity='0.5'>Last update : {
							new Date(device.lastUpdatedAt).toLocaleString()
						}
						</Text>
						<Button
							colorScheme='red'
							width='100%'
							leftIcon={<i className="fa-solid fa-trash-can"></i>}
							onClick={() => deleteModal.onOpen()}
						>
							Delete Device
						</Button>
						<DeviceDeleteModal
							isOpen={deleteModal.isOpen}
							onClose={() => deleteModal.onClose()}
							device={device}
						/>
					</Stack>
				</Stack>
			  </AccordionPanel>
			</AccordionItem>
		);
}
