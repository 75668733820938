import { useState, useEffect, useContext } from 'react'
import { GroupContext } from '../context/GroupContext';
import config from '../config';
import useAlert from './useAlert';

export default function useProducts() {

	const [products, setProducts] = useState([]);
	const [menus, setMenus] = useState([]);
	const { group } = useContext(GroupContext)
	const { alertError, alertSuccess } = useAlert();

	const getProducts = () => {
		fetch(`${config.apiUrl}/api/groups/${group.name}/products`,{
			credentials: 'include',
		})
		.then(async (res) => {
			if (res.ok) {
				const	r = await res.json();
				setMenus(r.filter(product => product.categories.length > 0))
				setProducts(r.filter(product => product.categories.length === 0))
			}
			else {
				alertError("Failed to get products.")
			}
		})
	}

	useEffect(() => {
		if (group)
			getProducts();
		// eslint-disable-next-line
	}, [group])

	const deleteProduct = (id) => {
		fetch(`${config.apiUrl}/api/groups/${group.name}/products/${id}`, {
			method: 'DELETE',
			credentials: 'include',
		})
		.then(async (res) => {
			if (res.ok) {
				alertSuccess('Item Deleted')
				getProducts();
			}
			else {
				alertError("Failed to delete product.")
			}
		})
	}

	async function addProduct (product) {
		const res = await fetch(`${config.apiUrl}/api/groups/${group.name}/products`, {
			method: 'POST',
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(product)
		})
		if (res.ok)
		{
			alertSuccess('Item Added');
			await getProducts();
			return (true)
		}
		alertError("Failed to add product.")
		return (false)
	}

	const updateProduct = async (product, id) => {
		const res = await fetch(`${config.apiUrl}/api/groups/${group.name}/products/${id}`, {
			method: 'PATCH',
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(product)
		})
	
		if (res.ok)
		{
			alertSuccess('Item Updated')
			await getProducts();
			return (true)
		}
		alertError("Failed to edit product.")
		return (false)
	}

	return {
		products, menus,
		deleteProduct, addProduct, updateProduct
	}
}
