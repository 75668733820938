import { Button, Flex, FormControl, FormHelperText, FormLabel, Heading, Stack, Switch, useColorMode, useDisclosure } from '@chakra-ui/react';
import SidebarLogoutModal from '../../components/Sidebar/SidebarLogoutModal';
import useSettings from '../../hooks/useSettings';

export default function SettingsGeneral({SAFI, ...props}) {

	const SAFIStatus = localStorage.getItem('SAFI');
	const handleSAFI = (e) => {
		localStorage.setItem('SAFI', e.target.checked);
	}

	const { colorMode, toggleColorMode } = useColorMode();

	const {isOpen, onOpen, onClose} = useDisclosure()

	const {settings, setDiscordNotification} = useSettings();

	return (
		<Stack
			{...props}
		>
			<Heading size='md' marginY='16px'>General</Heading>


			{SAFI && <FormControl width='100%'>
				<Flex
					alignItems='center'
					justifyContent='space-between'
				>
					<FormLabel mb='0'>Set as finish instant</FormLabel>
					<Switch defaultChecked={SAFIStatus === 'true'} onChange={handleSAFI} />
				</Flex>
				<FormHelperText>
					Orders will be set as finished as soon as they are started
				</FormHelperText>
			</FormControl>}



			<FormControl width='100%' marginY='16px'>
				<Flex
					alignItems='center'
					justifyContent='space-between'
				>
					<FormLabel mb='0'>Color theme</FormLabel>
					<Switch isChecked={colorMode === 'dark'} onChange={toggleColorMode} />
				</Flex>
				<FormHelperText>
					Enable dark theme
				</FormHelperText>
			</FormControl>


			{
			settings && setDiscordNotification &&
			<FormControl width='100%' marginY='16px'>
				<Flex
					alignItems='center'
					justifyContent='space-between'
				>
					<FormLabel mb='0'>Discord Notification</FormLabel>
					<Switch isChecked={settings.notifications.discordPing} onChange={(e) => {setDiscordNotification(e.target.checked)}} />
				</Flex>
				<FormHelperText>
					Disable or enable discord ping when an order is completed
				</FormHelperText>
			</FormControl>
			}


			<FormControl width='100%' marginY='16px' display='flex' justifyContent='space-between'>
				<Stack
					gap='0'
					justifyContent='center'
				>
					<FormLabel mb='0'>Disconnect</FormLabel>
				</Stack>
				<Button
					colorScheme='red'
					leftIcon={<i className="fa-solid fa-arrow-right-from-bracket"></i>}
					onClick={onOpen}
				>
					Logout
				</Button>
				<SidebarLogoutModal
					isOpen={isOpen}
					onClose={onClose}
				/>
			</FormControl>


			
		</Stack>
	);
}
