import {
	Avatar,
	Button,
	Container,
	DrawerFooter,
	Text,
  } from '@chakra-ui/react'
import { UserContext } from '../../context/UserContext';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

export default function SidebarFooter() {

	const {me} = useContext(UserContext);

	if (me)
	return (
		<DrawerFooter height='10vh'>
			{me.type === 'user' &&
			<Button
				width='100%'
				height='auto'
				paddingY='8px'
				justifyContent='space-between'
				colorScheme='blue'
				rightIcon={<i className="fa-solid fa-house"></i>}
				as={Link}
				to='/'
			>
				<Container
					display='flex'
					alignItems='center'
					padding='0'
				>
					<Avatar
						size='md'
						name={me.login}
						src={me.intraUser.imageLink}
					/>
					<Text
						fontSize='lg'
						marginInline='12px'
					>
						{me.login}
					</Text>
				</Container>
			</Button>}

			{me.type === 'device' &&
			<Button
				width='100%'
				height='auto'
				paddingY='8px'
				justifyContent='space-between'
				colorScheme='blue'
				_hover={{}}
				cursor='default'
			>
				<Container
					display='flex'
					alignItems='center'
					padding='0'
				>
					<Avatar
						size='md'
						name={me.login}
						src={me.intraUser.imageLink}
					/>
					<Text
						fontSize='lg'
						marginInline='12px'
					>
						{me.login}
					</Text>
				</Container>
			</Button>}
          </DrawerFooter>
	);
}