import { useState, useEffect, useContext } from 'react';
import config from '../config';
import useAlert from './useAlert';
import { UserContext } from '../context/UserContext';

export default function useSettings() {

	const [settings, setSettings] = useState();
	const { alertError, alertSuccess } = useAlert();

	const { me } = useContext(UserContext);

	useEffect(() => {
		async function fetchSettings() {
			const res = await fetch(`${config.apiUrl}/api/me/settings`, {
				credentials: 'include',
			});
			if (res.ok) {
				const data = await res.json();
				setSettings(data);
			} else {
				alertError('Failed to fetch settings');
			}
		}
		if (me && me.type === 'user')
			fetchSettings();
	// eslint-disable-next-line
	}, [])

	const patchSettings = async (data) => {
		const res = await fetch(`${config.apiUrl}/api/me/settings`, {
			method: 'PATCH',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		});
		if (res.ok) {
			alertSuccess('Settings updated');
			const newSettings = await res.json();
			setSettings(newSettings);
		} else {
			alertError('Failed to update settings');
		}
	}

	const setDiscordNotification = async (value) => {
		patchSettings({notifications: {
			discordPing: value,
		}});
	}

	return ({settings, setDiscordNotification})
}

