import { Accordion, Flex, Heading, IconButton, useDisclosure } from "@chakra-ui/react";
import MenuListItem from "./MenuListItem";
import NewMenuModal from "../NewMenu/NewMenuModal";

export default function MenuList({menus, deleteMenu, addMenu, updateMenu, productList, ...props}) {

	const { isOpen, onOpen, onClose } = useDisclosure()

	return (
		<Flex
			{...props}
			flexDir='column'
			width='100%'
			alignItems='center'
		>
			<NewMenuModal isOpen={isOpen} onClose={onClose} onSave={(p) => addMenu(p)} productList={productList}/>
			<Flex width='90%' alignItems='center' justifyContent='space-between'>
				<Heading size='sm' marginY='16px'>Menus</Heading>
				<IconButton colorScheme="green" onClick={onOpen} icon={<i className="fa-solid fa-plus"></i>} isDisabled={productList.length <= 0}/>
			</Flex>
			<Accordion allowMultiple width='90%'>
				{menus.map(menu => (<MenuListItem key={menu._id} menu={menu} deleteMenu={deleteMenu} updateMenu={updateMenu} productList={productList} />))}
			</Accordion>
		</Flex>
	);
}
