import { Avatar, Card, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import OrderCardProgress from "./OrderCardProgress";

export default function OrderCard({ order, ...props }) {

	return (
		<Card
			variant={order.state === 4 ? 'filled' : 'elevated'}
			{...props}
			display='flex'
			padding='24px'
			borderRadius='1.5vw'
			justifyContent='space-around'
		>
				<Text>Order #{order.orderNumber}</Text>
				<Flex
					alignItems='center'
					width='100%'
					justifyContent='space-between'
				>
					<Stack
						height='100%'
					>
						<Heading
							size='sm'
						>
							{order.state === 1 && 'Your order has been accepted'}
							{order.state === 2 && `We are preparing your order`}
							{order.state === 3 && 'Your order is ready to pickup'}
							{order.state === 4 && 'Thanks for ordering'}
						</Heading>
						<Flex
							width='100%'
							alignItems='center'
						>
							<Text
								color='green.500'
							>
								{order.user.login}
							</Text>
							{ order.estimatedCompletionTime !== null &&
								<>
									<i style={{fontSize: '4px', marginInline: '16px'}} className="fa-solid fa-circle"></i>
									<Text>
										{new Date(order.estimatedCompletionTime) > Date.now() ?
										`Ready at ${new Date(order.estimatedCompletionTime).toLocaleTimeString("en-US", {hour: '2-digit', minute: '2-digit'})}`
										: `Comming soon`}
									</Text>
								</>
							}
						</Flex>
					</Stack>
					<Avatar
						size='lg'
						name={order.user.login}
						src={order.user.intraUser ? order.user.intraUser.imageLink : '/defaultUser.jpg'}
						border='4px solid white'
					/>
				</Flex>
				<OrderCardProgress
					paddingTop='16px'
					order={order}
					maxStep={4}
					width='100%'
				/>
		</Card>
	);
}