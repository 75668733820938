import {
	Button,
  } from '@chakra-ui/react'

export default function SidebarButton({selected, children, ...props}) {

	//const [loading, setLoading] = useState(false);

	return (
		<Button
			justifyContent='space-between'
			width='100%'
			as='a'
			marginY='2px'
			variant={selected ? 'solid' : 'ghost'}
			iconSpacing='12px'
			//isLoading={loading}
			loadingText="Loading"
			spinnerPlacement='end'
			//onClick={() => setLoading(true)}
			{...props}
		>
			{children}
		</Button>
	);
}
