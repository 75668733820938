import {
	Text,
	Avatar,
	Flex
} from '@chakra-ui/react';

export default function CheckoutModalUserCard({user, setUser}) {

	return (
		<Flex
			flexDirection="row"
			paddingInline="1vw"
			overflow="hidden"
			alignItems='center'
			onClick={() => setUser(user.login)}
			margin='4px'
		>
			<Avatar
				marginY='1vh'
				size='sm'
				name={user.login}
				src={user.intraUser ? user.intraUser.imageLink : '/defaultUser.jpg'}
			/>
			<Text
				marginInline='2vw'
				fontSize='md'
			>
				{user.login}
			</Text>
		</Flex>
	);
}