import {
	AccordionButton,
	AccordionIcon,
	Box,
	Text,
	Flex,
	Avatar,
	Button,
	Tag,
	useColorModeValue,
} from '@chakra-ui/react'
import { useContext, useState } from 'react';
import { UserContext } from '../../context/UserContext';


export default function OrderItemExpand({openDrawer, order})
{

	const onOpenDrawer = (e) => {
		e.stopPropagation();
		openDrawer();
	}

	const [isWaiting, setIsWaiting] = useState(false);
	const {me} = useContext(UserContext);

	const onStart = async (e) => {
		e.stopPropagation();
		setIsWaiting(true);
		const res = await order.patch({state: 2});
		if (res.ok) {
			openDrawer();
		}
		setIsWaiting(false);
	}

	const setAsFinish = async (e) => {
		e.stopPropagation();
		setIsWaiting(true);
		const res = await order.patch({state: 3});
		if (res.ok) {
			setIsWaiting(false);
		}
	}

	const SAFI = localStorage.getItem('SAFI');

	const bgEnded = useColorModeValue('gray.100', 'gray.800');
	const bg = useColorModeValue('gray.100', 'gray.700');

	return (
		<AccordionButton as="div" justifyContent="space-between"
			bg={order.state === 4 ? bgEnded : bg}
		>
			<Box display="flex" justifyContent='space-between' alignItems='center' w="90%">
				<Flex alignItems='center'>
					<Avatar
						size='md'
						name={order.user.login}
						src={order.user.intraUser ? order.user.intraUser.imageLink : '/defaultUser.jpg'}
					/>
					<Text opacity='1' marginX='8px'>
						{order.user.login}
					</Text>
					<Text
						as='span'
						color='gray.500'
					>
						#{order.orderNumber}
					</Text>
				</Flex>
				{order.state === 1 && !(SAFI === 'true') &&
					<Button
						colorScheme='blue'
						bg='blue.300'
						leftIcon={<i className="fa-regular fa-hand"></i>}
						as='a'
						onClick={onStart}
						isLoading={isWaiting}
					>
						Start
					</Button>
				}
				{order.state === 1 && SAFI === 'true' &&
					<Button
						colorScheme='green'
						leftIcon={<i className="fa-solid fa-check"></i>}
						isLoading={isWaiting}
						onClick={setAsFinish}
					>
						Set as finish
					</Button> 
				}
				{order.state === 2 && me && order.assignedTo.login === me.login &&
					<Button
						colorScheme='green'
						variant='outline'
						leftIcon={<i className="fa-regular fa-hourglass"></i>}
						as='a'
						onClick={onOpenDrawer}
					>
						Continue
					</Button>
				}
				{(order.state === 3 || order.state === 4) && 
					<Tag colorScheme={me && order.assignedTo.login === me.login ? 'green' : 'gray'}>
						Close by {order.assignedTo.login}
					</Tag>
				}
			</Box>
			<AccordionIcon/>
		</AccordionButton>
	);
}