import { useContext, useEffect, useState } from "react";
import { GroupContext } from "../context/GroupContext";
import useAlert from "./useAlert";
import config from "../config";

export default function useShifts() {

	const [shifts, setShifts] = useState([]);
	const { group } = useContext(GroupContext)
	const { alertError, alertSuccess } = useAlert();

	async function fetchShifts() {
		const response = await fetch(`${config.apiUrl}/api/groups/${group.name}/shifts`, {
			credentials: 'include',
		});
		if (response.ok)
		{
			const data = await response.json();
			setShifts(data);
		} else
			alertError(`Failed to fetch shifts`);
	}

	useEffect(() => {
		if (group && shifts.length === 0)
			fetchShifts()
		// eslint-disable-next-line
	}, [group]);

	const createShift = async (shift) => {
		const response = await fetch(`${config.apiUrl}/api/groups/${group.name}/shifts`, {
			method: 'POST',
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(shift)
		});
		if (response.ok) {
			alertSuccess('Shift Created');
			fetchShifts();
		}
		else
			alertError('Failed to add shift');
	}

	const updateShift = async (shift) => {
		const response = await fetch(`${config.apiUrl}/api/groups/${group.name}/shifts/${shift._id}`, {
			method: 'PATCH',
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(shift)
		});
		if (response.ok) {
			alertSuccess('Shift Updated');
			fetchShifts();
		}
		else
			alertError('Failed to update shift');
	}

	const deleteShift = async (shift) => {
		const response = await fetch(`${config.apiUrl}/api/groups/${group.name}/shifts/${shift._id}`, {
			method: 'DELETE',
			credentials: 'include',
		});
		if (response.ok) {
			alertSuccess('Shift Deleted');
			fetchShifts();
		}
		else
			alertError('Failed to delete shift');
	}

	return ({shifts, createShift, updateShift, deleteShift})
}

