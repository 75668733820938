import { Button, Flex } from '@chakra-ui/react';
import UserCard from '../../components/UserCard/UserCard';
import UserRoles from '../../components/UserRoles/UserRoles';
import UserData from '../../components/UserData/UserData';
import useUser from '../../hooks/useUser';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import Header from '../../components/Header/Header';


export default function User() {

	const user = useUser();
	const { me } = useContext(UserContext);

	if (user && me)
		return (
			<div className='App'>
				<Header openMenu={() => window.history.back()}
					icon={<i className="fa-solid fa-arrow-left"></i>}
				/>
				<Flex
					flexDir='column'
					justifyContent='flex-start'
					width='100%' height='calc(100% - 72px)'
				>
					<Flex
						width='100vw'
						flexDir='column'
						alignItems='center'
					>
						<UserCard
							user={user}
							marginY='12px'
							width='90vw'
						/>
						<UserRoles
							width='90vw'
							user={user}
							me={me}
						/>
						<UserData width='90vw'
							user={user}
						/>
						{me.isSuperAdmin &&
							<Button
								width='90vw'
								marginTop='16px'
								colorScheme='red'
								leftIcon={<i className="fa-solid fa-trash-can"></i>}
								onClick={() => user.delete()}
							>
								Delete User
							</Button>
						}
					</Flex>
				</Flex>
			</div>
		);
}
