import { Flex, FormControl, FormLabel, IconButton, Input } from "@chakra-ui/react";

export default function NewProductForm({ product, edit }) {

	return (
		<Flex flexDir='column'>
			<FormControl marginY='8px'>
				<FormLabel>Product Name</FormLabel>
				<Input defaultValue={product.name} type='text' onChange={(e) => edit.setName(e.target.value)}/>
			</FormControl>
			<FormControl marginY='8px'>
				<FormLabel>Product Image</FormLabel>
				<Input defaultValue={product.imageUrl} type='text' onChange={(e) => edit.setImageUrl(e.target.value)}/>
			</FormControl>
			<Flex marginY='8px'>
				<FormControl marginRight='4px'>
					<FormLabel>Price</FormLabel>
					<Input defaultValue={product.price} type='number' onChange={(e) => edit.setPrice(e.target.value)}/>
				</FormControl>
				<FormControl marginLeft='4px'>
					<FormLabel>Production Cost</FormLabel>
					<Input defaultValue={product.productionCost} type='number' onChange={(e) => edit.setProductionCost(e.target.value)}/>
				</FormControl>
			</Flex>
			<Flex marginY='8px'>
				<FormControl marginRight='4px'>
					<FormLabel>Default Quantity</FormLabel>
					<Input defaultValue={product.defaultOrderQuantity} type='number' onChange={(e) => edit.setDefaultOrderQuantity(e.target.value)}/>
				</FormControl>
				<FormControl marginLeft='4px'>
					<FormLabel>Stock</FormLabel>
					<Input defaultValue={product.stock} type='number' onChange={(e) => edit.setStock(e.target.value)}/>
				</FormControl>
			</Flex>
			<Flex overflowY='scroll' marginY='8px' flexDir='column'>
				<Flex marginY='4px' width='100%' justifyContent='space-between' alignItems='center'>
					Details
					<IconButton
						icon={<i className="fa-solid fa-plus"></i>}
						onClick={() => edit.addDetails()}
					/>
				</Flex>
				{product.details.map((detail, index) => (
					<Flex alignItems='center' key={index}>
						<Input
							marginY='4px' type='text'
							value={detail} onChange={(e) => edit.changeDetails(index, e.target.value)}
							placeContent="Details Name"
						/>
						<IconButton
							colorScheme="red"
							icon={<i className="fa-solid fa-minus"></i>}
							marginLeft='8px'
							onClick={() => edit.deleteDetails(index)}
						/>
					</Flex>
				))}
			</Flex>
		</Flex>
	);
}