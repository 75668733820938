import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button
} from '@chakra-ui/react'
import config from '../../config';

export default function SidebarLogoutModal({isOpen, onClose}) {

	return (
		<Modal isCentered isOpen={isOpen} onClose={onClose}>
			<ModalOverlay
				bg='blackAlpha.300'
				backdropFilter='blur(10px)'
			/>
			<ModalContent>
				<ModalHeader>Logout</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					Are you leaving us already?
				</ModalBody>
				<ModalFooter>
					<Button
						leftIcon={<i className="fa-solid fa-arrow-right-from-bracket"></i>}
						colorScheme='red' mr={3}
						as='a'
						href={`${config.apiUrl}/auth/logout`}
					>
						Logout
					</Button>
					<Button onClick={onClose}>Close</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}