import { Flex, Heading, IconButton, Stack, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import SettingsGeneral from '../components/Settings/SettingsGeneral';


export default function Settings() {

	const bg = useColorModeValue('gray.100', 'gray.700');

	return (
		<Stack width='100vw' height='100vh' overflow='hidden' alignItems='center'>
			<Flex
				paddingY='16px'
				bg={bg}
				width='100%'
				justifyContent='center'
				alignItems='center'
			>
				<Heading size='lg'>ft_waitlist</Heading>
				<IconButton
					icon={<i className="fa-solid fa-arrow-left"></i>}
					position='absolute'
					left='16px'
					variant='ghost'
					as={Link}
					to='/'
				/>
			</Flex>
			<Stack width='90%' marginY='16px'>
				<Heading marginY='8px'>Settings</Heading>
				<SettingsGeneral/>
			</Stack>
		</Stack>
	);
}
