import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import NewMenuForm from "./NewMenuForm";
import { useState } from 'react';
import useMenu from "../../hooks/useMenu";



export default function NewMenuModal({isOpen, onClose, defaultMenu, onSave, productList}) {

  // // name, price, imageUrl, defaultQuantity, productionCost, stock, details
  // const [name, setName] = useState(menu ? menu.name : '');
  // const [price, setPrice] = useState(menu ? menu.price : 0);
  // const [imageUrl, setImageUrl] = useState(menu ? menu.imageUrl : '');
  // const [products, setProducts] = useState(menu ? menu.products : [productList[0]] );

  // const newProduct = async () => {
  //   if (addProduct({name, price, imageUrl, products}, menu._id))
  //     onClose();
  // }

  const {menu, isValid, edit} = useMenu(defaultMenu);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const saveMenu = async () => {
    setIsSubmitting(true);
    if (onSave(menu, menu._id))
      onClose();
    setIsSubmitting(false);
  }

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Menu</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
           <NewMenuForm
              edit={edit}
              productList={productList}
              menu={menu}
           />
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr='8px' variant='outline'>
              Close
            </Button>
            <Button
              isLoading={isSubmitting}
              colorScheme='green' onClick={() => saveMenu()}
              isDisabled={!isValid}
            >Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
	);
}