import { Accordion, Flex, Heading, Text } from '@chakra-ui/react';
import AdminTemplate from '../../templates/AdminTemplate';
import OrderItem from '../../components/OrderItem/OrderItem';
import useOrders from '../../hooks/useOrders';

export default function Orders() {

	const { orders, expandIndex, handleExpand } = useOrders();

	return (
		<AdminTemplate>
			<Flex width='100%' justifyContent='space-between' alignItems='center' minHeight='64px' paddingX='5%'>
				<Heading size='sm'>Orders</Heading>
				<Text>{orders.filter(o => o.state > 2).length}/{orders.length}</Text>
			</Flex>
			<Accordion
				paddingBottom='60%'
				overflowY='scroll'
				allowMultiple
				index={expandIndex}
				onChange={(indexes) => handleExpand(indexes, orders)}
			>
				{orders.map(order => (
					<OrderItem
						key={order._id}
						order={order}
					/>
				))}
			</Accordion>
		</AdminTemplate>
	);
}
