import {
	Modal,
	ModalBody,
	ModalContent,
	ModalCloseButton,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Button,
	Text,
} from '@chakra-ui/react';
import { useState } from 'react';

export default function DeviceRefreshModal({isOpen, onClose, device}) {

	const [loading, setLoading] = useState(false);

	const onRefresh = async () => {
		setLoading(true);
		const response = await device.refreshToken();
		if (response.status === 200) {
			onClose();
		}
		setLoading(false);
	}

	return (
	<Modal
        isOpen={isOpen}
        onClose={onClose}
		isCentered
      >
        <ModalOverlay />
        <ModalContent>
			<ModalHeader
				width={'90%'}
			>
				Refresh {device.name} token.
			</ModalHeader>
			<ModalCloseButton />
			<ModalBody pb={6}>
				<Text>
					Refreshing the token will invalidate the previous one.
				</Text>
			</ModalBody>
			<ModalFooter>
				<Button
					colorScheme='blue'
					mr={3}
					leftIcon={<i className="fa-solid fa-sync"></i>}
					onClick={() => onRefresh()}
					isLoading={loading}
				>
					Refresh
				</Button>
				<Button onClick={onClose}>Cancel</Button>
			</ModalFooter>
		</ModalContent>
    </Modal>
	);
}