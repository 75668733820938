import { useEffect, useState } from "react";


export default function useProduct(product = {
	name: '',
	price: 0,
	imageUrl: null,
	defaultOrderQuantity: 1,
	productionCost: 0,
	stock: 0,
	details: ['']
}) {

	const [name, setName] = useState(product.name);
	const [price, setPrice] = useState(product.price);
	const [imageUrl, setImageUrl] = useState(product.imageUrl);
	const [defaultOrderQuantity, setDefaultOrderQuantity] = useState(product.defaultOrderQuantity);
	const [productionCost, setProductionCost] = useState(product.productionCost);
	const [stock, setStock] = useState(product.stock);
	const [details, setDetails] = useState(product.details);
	const [isValid, setIsValid] = useState(false);

	useEffect(() => {
		if (name.length > 0
			&& price >= 0 && price.toString().length > 0
			&& defaultOrderQuantity.toString().length > 0 && defaultOrderQuantity >= 0
			&& productionCost >= 0 && productionCost.toString().length > 0
			&& stock >= 0 && stock.toString().length > 0
			&& details.filter(detail => detail.length === 0).length === 0
		)
			setIsValid(true);
		else
			setIsValid(false);
	}, [name, price, imageUrl, defaultOrderQuantity, productionCost, stock, details])

	const addDetails = () => {
		setDetails([...details, ""])
	}

	const deleteDetails = (index) => {
		var newDetails = [...details];
		newDetails.splice(index, 1)
		setDetails(newDetails)
	}

	const changeDetails = (index, value) => {
		var	newDetails = [...details];
		newDetails[index] = value;
		setDetails(newDetails)
	}

	return ({
		product: {
			_id: product._id,
			name,
			price,
			imageUrl,
			defaultOrderQuantity,
			productionCost,
			stock,
			details
		},
		isValid,
		edit: {
			setName,
			setPrice,
			setImageUrl,
			setDefaultOrderQuantity,
			setProductionCost,
			setStock,
			addDetails,
			deleteDetails,
			changeDetails,
		}
	})
}
	

