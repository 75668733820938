import { Button, Flex, Heading, SimpleGrid, Stack, useDisclosure } from '@chakra-ui/react';
import AdminTemplate from '../../templates/AdminTemplate';
import CheckoutItem from '../../components/CheckoutItem/CheckoutItem';
import { useEffect } from 'react';
import useCart from '../../hooks/useCart';
import CartDrawer from '../../components/CartDrawer/CartDrawer';

export default function Checkout() {

	const {isOpen, onOpen, onClose} = useDisclosure();

	const { cart, shiftProducts } = useCart();

	useEffect(() => {
		if (cart.items.length === 0 && isOpen) {
			onClose();
		}
		// eslint-disable-next-line
	}, [cart]);

	if (shiftProducts)
	return (
		<AdminTemplate>
			<Flex
				width='100%' minHeight='64px'
				justifyContent='space-between' alignItems='center'
				paddingX='5%'
				borderBottom='solid 1px'
				borderColor='gray.200'
			>
				<Heading size='sm'>Checkout</Heading>
				<Button
					rightIcon={<i className="fa-solid fa-shopping-cart"></i>}
					variant='outline'
					colorScheme='blue'
					onClick={onOpen}
					isDisabled={cart.items.length === 0}
				>
					Open cart
				</Button>
			</Flex>
			<Stack overflowY='scroll' paddingBottom='60%'>
				<SimpleGrid columns={2} spacing='16px' margin='16px'>
					{shiftProducts.map((product) => (
						<CheckoutItem
							cart={cart}
							item={product}
							key={product._id}
							width='100%'
							height='20vh'
						/>
					))}
				</SimpleGrid>
			</Stack>
			<CartDrawer
				cart={cart}
				isOpen={isOpen}
				onClose={onClose}
			/>
		</AdminTemplate>
	);
}
