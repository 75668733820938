import { Button, Card, CardBody, CardFooter, Input } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import { GroupContext } from '../../context/GroupContext';

export default function ShiftCardCreate({createShift ,...props}) {

	const [name, setName] = useState('');
	const [isAdmin, setIsAdmin] = useState(false);

	const {me} = useContext(UserContext);
	const {group} = useContext(GroupContext);

	useEffect(() => {
		if (group && me)
			setIsAdmin((group.admins.find((admin) => admin._id === me._id) || group.owner._id === me._id));
	}, [group, me]);

	if (isAdmin)
		return (
			<Card
				variant='outline'
				{...props}
			>
				<CardBody
					display='flex'
					alignItems='center'
				>
					<Input
						value={name}
						onChange={(e) => setName(e.target.value)}
						placeholder='Shift name'
					/>
				</CardBody>
				<CardFooter display='flex' alignItems='center' justifyContent='center'>
					<Button
						isDisabled={name === ''}
						colorScheme='green'
						width='90%'
						onClick={() => {createShift({name}); setName('')}}
					>
						Create Shift
					</Button>
				</CardFooter>
			</Card>
		);
}
