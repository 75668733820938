import { Button, Card, CardBody, CardFooter, FormControl, FormHelperText, FormLabel, Heading, Input, Select } from '@chakra-ui/react';
import { useState } from 'react';

export default function NewDeviceCard({createDevice, ...props}) {

		const [name, setName] = useState('');
		const [permission, setPermission] = useState('member');

		return (
			<Card
				variant='outline'
				{...props}
			>
				<CardBody
					display='flex'
					flexDir='column'
					gap='8px'
				>
					<Heading size='md'>Device creation</Heading>
					<FormControl
						isRequired
						my='16px'
					>
						<FormLabel>Device Name</FormLabel>
						<Input
							type='text'
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</FormControl>
					<FormControl>
						<FormLabel>Permission</FormLabel>
						<Select
							value={permission}
							onChange={(e) => setPermission(e.target.value)}
						>
							<option value='member'>Member</option>
							<option value='admin'>Administrator</option>
						</Select>
						<FormHelperText>
							Administrators can create and delete shifts and products.
						</FormHelperText>
					</FormControl>
				</CardBody>
				<CardFooter display='flex' alignItems='center' justifyContent='center'>
					<Button
						isDisabled={name === ''}
						onClick={() => {createDevice(name, permission); setName(''); setPermission('member')}}
						colorScheme='green'
						width='90%'
						leftIcon={<i className="fa-solid fa-plus"></i>}
					>
						Add new device
					</Button>
				</CardFooter>
			</Card>
		);
}
