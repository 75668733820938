import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, IconButton, Image, ListItem, Text, UnorderedList, useDisclosure } from "@chakra-ui/react";
import NewMenuModal from "../NewMenu/NewMenuModal";

export default function MenuListItem({menu, updateMenu, deleteMenu, productList, ...props}) {

	const { isOpen, onOpen, onClose } = useDisclosure()

	return (
		<AccordionItem width='100%'
			{...props}
		>
			<AccordionButton
				width='100%'
				alignItems='center'
				justifyContent='space-between'
				display='flex'
				as='div'
			>
					<Flex alignItems='center'>
						<Image
							boxSize='32px'
							objectFit='cover'
							borderRadius='full'
							src={menu.imageUrl ? menu.imageUrl : '/defaultUser.jpg'}
							alt={menu.name}
						/>
						<Text marginX='8px'>{menu.name} {menu.price}€</Text>
					</Flex>
					<Flex alignItems='center'>
						<IconButton mr='4px' onClick={(e) => {e.stopPropagation(); onOpen()}} icon={<i className="fa-regular fa-pen-to-square"></i>}/>
						<IconButton mr='4px' colorScheme="red" icon={<i className="fa-solid fa-trash-can"></i>} onClick={(e) => {e.stopPropagation(); deleteMenu(menu._id)}}/>
						<AccordionIcon
							width='40px'
						/>
					</Flex>
					<NewMenuModal isOpen={isOpen} onClose={onClose} productList={productList} onSave={(p, id) => updateMenu(p, id)} defaultMenu={menu}/>
			</AccordionButton>
			<AccordionPanel pb={4}>
				<UnorderedList>
					{menu.categories.map((category, index) => (<ListItem key={index}>{category.name}</ListItem>))}
				</UnorderedList>
			</AccordionPanel>
		</AccordionItem>
	);
}
