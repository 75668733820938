import {
	DrawerHeader,
	Flex,
	Heading,
	IconButton,
	useColorModeValue,
  } from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'

export default function SidebarHeader({onClose}) {

	const bg = useColorModeValue('gray.200', 'gray.600');
	return (
		<DrawerHeader backgroundColor={bg}>
		<Flex justifyContent='space-between' alignItems='center'>
			<Heading size='lg' paddingInline='12px'>ft_waitlist</Heading>
			<IconButton icon={<CloseIcon />} variant='ghost' onClick={onClose} />
		</Flex>
		</DrawerHeader>
	);
}