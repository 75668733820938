import {
	Flex,
	Tag,
	TagLabel,
	TagRightIcon,
	Text
  } from '@chakra-ui/react'


  function Star() {
	return (
		<i className="fa-regular fa-star"></i>
	);
}

function Crown() {
	return (
		<i className="fa-solid fa-crown"></i>
	);
}

export default function UserTableGroups(params)
{

	if (params.value.length === 0)
	{
		return (
			<Flex height='100%' align='center'>
				<Text color='lightgray'>No groups</Text>
			</Flex>
		);
	}

	return (
		<Flex height='100%' align='center'>
			{params.value.groups.map(group => (
				<Tag
					key={group.name}
					colorScheme={group.color}
					marginInline='8px'
					borderRadius='full'
				>
					<TagLabel>{group.name} </TagLabel>
					{group.admins.find(adminId => (adminId === params.value.userId)) && <TagRightIcon boxSize='12px' as={Star}/>}
					{group.owner === params.value.userId && <TagRightIcon boxSize='24px' as={Crown}/>}
				</Tag>
			))}
		</Flex>
	);

}