import { Avatar, Box, Card, Flex, Heading, Stack, Tag, Text, useColorModeValue } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import party from "party-js";

export default function OrderCardReady({ order, ...props }) {

	const partyRef = useRef(false)

	useEffect(() => {
		if (!partyRef.current)
		{
			if (Math.floor(Math.random() * 20) === 1)
				party.sparkles(document.getElementById(order._id));
			else
				party.confetti(document.getElementById(order._id));
			partyRef.current = true;
		}
		// eslint-disable-next-line
	}, [])

	const bgCard = useColorModeValue('green.50', 'green.800');

	return (
		<Card
			id={order._id}
			{...props}
			display='flex'
			overflow='hidden'
			padding='24px'
			borderRadius='1.5vw'
			justifyContent='space-between'
			alignItems='center'
			bg={bgCard}
		>
			<Flex
				alignItems='center'
				width='100%'
			>
				<Avatar
					size='xl'
					name={order.user.login}
					src={order.user.intraUser ? order.user.intraUser.imageLink : '/defaultUser.jpg'}
				/>
				<Stack marginInline='32px'>
					<Heading size='lg'>
						{order.user.intraUser ? order.user.intraUser.displayName : order.user.login}
					</Heading>
					<Text color='gray.500' fontSize='lg'>
						Order #{order.orderNumber}
					</Text>
				</Stack>
			</Flex>
			<Text
				width='80%'
				textAlign='center'
				fontSize='xl'
			>
				Order <Box as="b">#{order.orderNumber}</Box> is ready for pickup
			</Text>
			<Flex
				alignItems='center'
				justifyContent='space-between'
				width='100%'
			>
				<Text
					color='gray.500'
				>
					Ordered at {new Date(order.createdAt).toLocaleTimeString("en-US", {hour: '2-digit', minute: '2-digit'})}
				</Text>
				<Tag
					colorScheme='green'
				>
					Order prepared by {order.assignedTo ? order.assignedTo.login : 'Yvette'}
				</Tag>
			</Flex>
		</Card>
	);
}