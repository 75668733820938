import {
	Text,
	Flex,
	AccordionPanel,
	useDisclosure,
	Stack,
} from '@chakra-ui/react'
import OrderItemEditMenu from './OrderItemEditMenu';
import OrderItemDeleteModal from './OrderItemDeleteModal';
import OrderItemList from './OrderItemList';


export default function OrderItemPanel({order})
{

	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<AccordionPanel pb={4}>
			<Stack>
				<OrderItemList order={order} />
				<Flex
					width='100%'
					justifyContent='space-between'
					alignItems='center'
					marginY='8px'
				>
					<Text color='gray.500'>
						{order.assignedTo &&
							`Order prepared by ${order.assignedTo.login}`
						}
						<br />
						Order made by {order.createdBy ? order.createdBy : 'Yvette'}
					</Text>
					<OrderItemEditMenu
						onDelete={onOpen}
						order={order}
					/>
				</Flex>
			</Stack>
			<OrderItemDeleteModal
				order={order}
				isOpen={isOpen}
				onClose={onClose}
			/>
		</AccordionPanel>
	);

}