import { useContext, useState } from 'react'
import config from '../config';
import { GroupContext } from '../context/GroupContext';
import useAlert from './useAlert';

export default function useCart()
{
	const [cart, setCart] = useState({items: [] , name: ''});
	const { group, shift } = useContext(GroupContext)

	const { alertError, alertSuccess } = useAlert();

	const addItem = (item) => {
		setCart({items: [...cart.items, item], name: cart.name});
	}

	const removeItem = (index) => {
		var newCart = {...cart};
		newCart.items.splice(index, 1);
		setCart(newCart);
	}
	
	const handleNameChange = (name) => {
		setCart({items: cart.items, name: name});
	}

	const sendOrder = async () => {
		console.log(cart);
		console.log({items: cart.items, user: cart.name});
		const response = await fetch(`${config.apiUrl}/api/groups/${group.name}/shifts/${shift._id}/orders`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({items: cart.items, user: cart.name}),
			credentials: 'include',
		});
		if (response.status === 200) {
			const data = await response.json();
			console.log(data);
			alertSuccess(`Order #${data.orderNumber} created`);
			setCart({items: [], name: ''});
		}
		else {
			alertError('error', 'Could not send order');
		}
		return response;
	}

	if (!shift)
		return {cart, shiftProducts: []};
	return {cart: {
		...cart,
		addItem: (item) => addItem(item),
		removeItem: (index) => removeItem(index),
		handleNameChange: (name) => handleNameChange(name),
		sendOrder: () => sendOrder(),
	}, shiftProducts: shift.products};
}
