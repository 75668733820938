import { Flex, Heading, Text } from '@chakra-ui/react';
import AdminTemplate from '../../templates/AdminTemplate';
import UserTable from '../../components/UserTable/UserTable';
import UserSearchBar from '../../components/UserSearch/UserSearchBar';
import UserPagination from '../../components/UserSearch/UserPagination';
import useUsers from '../../hooks/useUsers';

export default function Users() {

	const {
		users,
		search, onSearch,
		page, pageUp, pageDown,
		total
	} = useUsers()

	return (
		<AdminTemplate width='100vw'>
			<Flex width='100%' justifyContent='space-between' alignItems='center' minHeight='64px' paddingX='5%'>
				<Heading size='sm'>Users</Heading>
				<Text>{total} users</Text>
			</Flex>
			<Flex 
				alignItems='center'
				width='100%'
				flexDir='column'
			>
				<UserSearchBar
					width='90vw'
					marginY='16px'
					onSearch={onSearch}
					search={search}
				/>
				<UserTable users={users}
					height='60vh'
				/>
				<UserPagination
					width='90vw'
					marginY='16px'
					currentPage={page}
					maxPages={Math.floor(total/10) + (total % 10 >= 0)}
					nextPage={pageUp}
					previousPage={pageDown}
				/>
			</Flex>
			
		</AdminTemplate>
	);
}
