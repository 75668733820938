import { Card, CardBody, Flex, Heading, IconButton, Tag, Text } from '@chakra-ui/react';

export default function ShiftCardArchived({shift, ...props}) {

	return (
		<Card
			variant='filled'
			{...props}
			marginY='8px'
		>
			<CardBody
				display='flex'
				alignItems='center'
				padding='8px'
				justifyContent='space-between'
			>
				<Heading size='md'
					ml='8px'
					flex='2'
				>
					{shift.name}
				</Heading>
				<Text
					flex='2'
				>
					{new Date(shift.createdAt).toLocaleDateString()}
				</Text>
				<Flex
					flex='1'
					justifyContent='space-between'
				>
					<Tag colorScheme='green'>
							Ended
					</Tag>
					<IconButton
						icon={<i className="fa-solid fa-eye"></i>}
						colorScheme='blue'
						marginLeft='8px'
					/>
				</Flex>
			</CardBody>
		</Card>
	);
}
