import { Avatar, Card, Center, Flex, Grid, GridItem, Spinner, Text, useColorModeValue } from '@chakra-ui/react';
import useOrders from '../../hooks/useOrders';
import OrderCardProgress from '../../components/OrderCard/OrderCard';
import OrderCardReady from '../../components/OrderCard/OrderCardReady';
import InfoCard from '../../components/InfoCard/InfoCard';

export default function Waitlist() {

	const { orders } = useOrders()

	const bg = useColorModeValue('gray.50', 'gray.600');
	const bgCard = useColorModeValue('green.100', 'green.900');

	if (orders.length === 0)
		return (
			<Center
				width="100vw"
				height="100vh"
			>
				<Spinner 
					mr='8px'
				/>
				<Text>No order yet !</Text>
			</Center>
		)
	return (
		<Flex
			width='100vw'
			height='100vh'
			justifyContent='flex-end'
			overflow='hidden'
		>
			<Grid
				templateColumns='repeat(1, 1fr)'
				templateRows='repeat(6, 1fr)'
				bg={bg}
				gap={10}
				width='10%'
				height='100%'
				padding={10}
			>
				{orders.filter(order => order.state === 4).sort((a, b) => (new Date(b.lastUpdatedAt) - new Date(a.lastUpdatedAt))).slice(0, 6).filter(order => order.user).map((order) => (
					<GridItem
						key={order._id}
					>
						<Card
							display='flex'
							overflow='hidden'
							padding='24px'
							borderRadius='1.5vw'
							justifyContent='space-around'
							flexDir='column'
							bg={bgCard}
						>
							<Avatar
								size='lg'
								name={order.user.login}
								src={order.user.intraUser ? order.user.intraUser.imageLink : '/defaultUser.jpg'}
							/>
						</Card>
					</GridItem>
				))}
			</Grid>
			<Grid
				templateColumns='repeat(1, 1fr)'
				templateRows='repeat(2, 1fr)'
				gap={10}
				width='45%'
				height='100%'
				padding={10}
			>
				{orders.filter(order => order.state === 3).slice(0, 2).filter(order => order.user).map((order, index) => (
					<GridItem
						key={order._id}
					>
						<OrderCardReady
							order={order}
							height='100%'
							alignSelf='center'
							justifySelf='center'
							width='100%'
						/>
					</GridItem>
				))}
			</Grid>
			<Grid
				templateColumns='repeat(2, 1fr)'
				templateRows='repeat(4, 1fr)'
				gap={10}
				width='45%'
				height='100%'
				bg={bg}
				padding={10}
			>
				{orders.filter(order => order.state < 3).slice(0, 7).filter(order => order.user).map((order) => (
					<GridItem key={order._id}>
						<OrderCardProgress
							order={order}
							height='100%'
							alignSelf='center'
							justifySelf='center'
							width='100%'
						/>
					</GridItem>
				))}
				<GridItem
					colStart={2}
					rowStart={4}
				>
					<InfoCard
						height='100%'
						width='100%'
					/>
				</GridItem>
			</Grid>
		</Flex>
	);
}
