import { Avatar, Card, CardBody, CardFooter, Flex, Heading, IconButton, Tag, TagLabel, Text } from "@chakra-ui/react";
import { UserContext } from "../../context/UserContext";
import { useContext } from "react";

export default function UserCard({user, ...props}) {

	const {me} = useContext(UserContext);

	if (user)
		return (
			<Card
				{...props}
			>
				<CardBody
					display='flex'
					alignItems='center'
				>
					<Avatar
						size='lg'
						src={user.intraUser ? user.intraUser.imageLink : '/defaultUser.jpg'}
						as='a'
						href={`https://profile.intra.42.fr/users/${user.login}`}
					/>
					<Flex
						flexDir='column'
						marginInline='16px'
					>
						<Flex
							alignItems='center'
						>
							<Heading
								size='md'
								as='a'
								href={`https://profile.intra.42.fr/users/${user.login}`}
							>
								{user.login}
							</Heading>
							{me && me.isSuperAdmin && <IconButton
								icon={<i className="fa-solid fa-rotate-right"></i>}
								colorScheme="blue"
								size='xs'
								marginLeft='8px'
								onClick={(e) => {
									e.stopPropagation();
									user.refresh()}
								}
							/>}
						</Flex>
						<Text
							color='gray'
						>
							{user.intraUser ? user.intraUser.displayName : 'Unknown'}
						</Text>
					</Flex>
				</CardBody>
				{ user.groups.length > 0 &&
					<CardFooter>
						{user.groups.map(group => (
							<Tag
								key={group._id}
								colorScheme={group.color}
								marginInline='8px'
								borderRadius='full'
							>
								<TagLabel>{group.name}</TagLabel>
							</Tag>
						))}
					</CardFooter>
				}
			</Card>
		);
}