import { Flex, Avatar, IconButton, Stack, Heading, Tag, useColorModeValue, TagRightIcon, TagLabel, Center } from '@chakra-ui/react';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { Link } from 'react-router-dom';

export default function ProfileHeader({...props}) {

	const { me } = useContext(UserContext);

	const bg = useColorModeValue('gray.100', 'gray.700');

	if (me)
		return (
			<Stack width='100%' alignItems='center' {...props}>
				<Flex
					bg={bg}
					width='100%'
					alignItems='center'
					padding='16px'
					justifyContent='center'
					marginBottom='8px'
				>
					<Heading size='lg'>ft_waitlist</Heading>
					<IconButton
						icon={<i className="fa-solid fa-sliders"></i>}
						position='absolute'
						right='16px'
						colorScheme='blue'
						as={Link}
						to='/settings'
					/>
				</Flex>
				<Avatar
					size='xl'
					name={me.login}
					src={me.intraUser ? me.intraUser.imageLink : './defaultUser.jpg'}
					margin='24px'
				/>
				<Heading>{me.login}</Heading>
				<Flex
					flexWrap='wrap'
					justifyContent='center'
					gap='8px'
				>
					{me.groups.map((group) => (
						<Tag
							key={group._id}
							colorScheme={group.color}
							marginX='4px'
							paddingX='16px'
							paddingY='8px'
							borderRadius='full'
							as={Link}
							to={`/groups/${group.name}/shifts`}
						>
							<TagLabel>{group.name}</TagLabel>
							<TagRightIcon boxSize='12px' className="fa-solid fa-door-open" as={Center} />
						</Tag>
					))}
				</Flex>
			</Stack>
		);
}
