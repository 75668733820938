import { Flex, Heading, IconButton, useDisclosure } from "@chakra-ui/react";
import ProductListItem from "./ProductListItem";
import NewProductModal from "../NewProduct/NewProductModal";

export default function ProductList({products, addProduct, deleteProduct, updateProduct, ...props}) {

	const { isOpen, onOpen, onClose } = useDisclosure()

	return (
		<Flex
			{...props}
			flexDir='column'
			width='90%'
		>
			<NewProductModal isOpen={isOpen} onClose={onClose} onSave={(e) => addProduct(e)}/>
			<Flex alignItems='center' justifyContent='space-between'>
				<Heading size='sm' marginY='16px'>Products</Heading>
				<IconButton colorScheme="green" onClick={onOpen} icon={<i className="fa-solid fa-plus"></i>}/>
			</Flex>
			{products.map((product) => (<ProductListItem key={product._id} product={product} updateProduct={updateProduct} deleteProduct={deleteProduct}/>))}
		</Flex>
	);
}
