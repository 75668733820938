import {
	Modal,
	ModalBody,
	ModalContent,
	ModalCloseButton,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Button,
	Text,
} from '@chakra-ui/react';
import config from '../../config';
import { GroupContext } from '../../context/GroupContext';
import { useContext } from 'react';

export default function DeviceConnectModal({isOpen, onClose, device}) {

	const { group } = useContext(GroupContext);

	if (!device || !group) {
		return null;
	}
	return (
	<Modal
        isOpen={isOpen}
        onClose={onClose}
		isCentered
      >
        <ModalOverlay />
        <ModalContent>
			<ModalHeader
				width={'90%'}
			>
				Are you sure you want to connect to the device?
			</ModalHeader>
			<ModalCloseButton />
			<ModalBody pb={6}>
				<Text>
					You will be disconnected from your current account.
					The device token will also be refresh.
					Dont forget to disconnect from 42 intra.
				</Text>
			</ModalBody>
			<ModalFooter>
				<Button
					colorScheme='green'
					mr={3}
					leftIcon={<i className="fa-solid fa-arrow-right-to-bracket"></i>}
					as='a'
					href={`${config.apiUrl}/api/groups/${group.name}/devices/${device._id}/auth`}
					
				>
					Connect
				</Button>
				<Button onClick={onClose}>Cancel</Button>
			</ModalFooter>
		</ModalContent>
    </Modal>
	);
}