import { Card, Center, Flex, Heading, Image, Stack, Tag, Text, useColorModeValue } from "@chakra-ui/react";
import { useContext } from "react";
import { GroupContext } from "../../context/GroupContext";

export default function CheckoutMenuStep({subItem, category, onChange, ...props})
{
	
	const { shift } = useContext(GroupContext);
	const itemProduct = subItem ? shift.products.find(product => product._id === subItem.product._id) : null

	const onToggleDetail = (detail) => {
		if (!subItem.details.includes(detail))
			onChange({...subItem, details: [...subItem.details, detail]});
		else
			onChange({...subItem, details: subItem.details.filter(d => d !== detail)});
	
	}

	const bg = useColorModeValue('gray.100', 'gray.600');

	if (shift)
	return (
		<Stack
			width='100%'
			paddingY='16px'
			height='100%'
			bg={bg}
			{...props}
		>
			<Heading size='md' marginLeft='16px'>{category.name}</Heading>
			<Flex
				overflowX='scroll'
				overflowY='hidden'
				flexWrap='nowrap'
				width='100%'
				flex='1'
				alignItems='center'
			>
				{category.products.map(productId => {
					const product = shift.products.find(product => product._id === productId);
					return (
					<Card
						flexShrink='0'
						key={product._id}
						display='flex'
						alignItems='center'
						justifyContent='center'
						overflow='hidden'
						onClick={() => onChange({product, details: [], quantity: 1})}
						border='2px solid'
						borderColor={subItem && product._id === subItem.product._id ? 'blue.500' : 'white'}
						height='128px'
						width='128px'
						padding='16px'
						marginInline='8px'
					>
						<Image
							objectFit='cover'
							src={product.imageUrl ? product.imageUrl : 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/HD_transparent_picture.png/1280px-HD_transparent_picture.png'}
							alt={product.name}
							height='100%'
						/>
						<Stack
							pos='absolute'
							width='100%'
							height='100%'
							padding='16px'
						>
							<Heading size='sm'>{product.name}</Heading>
						</Stack>
					</Card>
				)})}
				<Card
					flexShrink='0'
					display='flex'
					alignItems='center'
					justifyContent='center'
					overflow='hidden'
					onClick={() => onChange(null)}
					border='2px solid'
					borderColor={!subItem ? 'blue.500' : 'white'}
					height='128px'
					width='128px'
					padding='16px'
					marginInline='8px'
				>
					<Image
						height='100%'
						objectFit='cover'
						src='https://cdn-icons-png.freepik.com/512/2039/2039083.png'
						alt='No product selected'
					/>
				</Card>
			</Flex>
			{itemProduct && itemProduct.details && itemProduct.details.length > 0 ?
			<Stack
				width='100%'
				alignItems='center'
				overflowY='scroll'
				flex='2'
				height='100%'
			>
				{itemProduct.details.map((detail, index) => (
					<Tag
						padding='16px'
						display='flex'
						justifyContent='center'
						key={index}
						onClick={() => onToggleDetail(detail)}
						colorScheme={subItem.details.includes(detail) ? 'green' : 'gray'}
						width='90%'
						marginY='8px'
					>
						{detail}
				</Tag>
				))}
			</Stack>
			:
			<Center
				flex={2}
			>
				<Text>No details available</Text>
			</Center>}
		</Stack>
	);

}