import {
	Drawer,
	DrawerBody,
	DrawerOverlay,
	DrawerContent,
	Stack,
	Divider,
	Center,
	Text,
	Tag,
	Flex,
	TagLabel,
  } from '@chakra-ui/react'
import SidebarHeader from './SidebarHeader'
import SidebarButton from './SidebarButton';
import SidebarFooter from './SidebarFooter';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { GroupContext } from '../../context/GroupContext';
import { UserContext } from '../../context/UserContext';

export default function Sidebar({isOpen, onClose}) {

	const { group, shift } = useContext(GroupContext);
	const { me } = useContext(UserContext);

	if (me)
	return (
		<Drawer
			isOpen={isOpen}
			onClose={onClose}
			placement='left'
			height='100dvh'
			isFullHeight
		>
			<DrawerOverlay />
			<DrawerContent
				height="100dvh"
			>
			<SidebarHeader onClose={onClose}/>
			<DrawerBody marginY='12px' display='flex' flexDir='column' justifyContent='space-between'>
				<Stack marginY='8px'>
					{shift !== null && localStorage.getItem('shift') ? (
						<Stack>
							<Flex alignItems='center' justifyContent='space-between'>
								<Tag colorScheme='green'>
									<TagLabel>
										{shift.name}
									</TagLabel>
								</Tag>
								<Divider width='80%' />
							</Flex>
							<SidebarButton
								rightIcon={<i className="fa-solid fa-receipt"></i>}
								to={`/groups/${group.name}/shifts/${shift._id}/orders`}
								as={Link}
							>
								Orders
							</SidebarButton>
							<SidebarButton
								rightIcon={<i className="fa-solid fa-cash-register"></i>}
								to={`/groups/${group.name}/shifts/${shift._id}/checkout`}
								as={Link}
							>
								Checkout
							</SidebarButton>
							<SidebarButton
								rightIcon={<i className="fa-solid fa-tv"></i>}
								to={`/groups/${group.name}/shifts/${shift._id}/waitlist`}
								as={Link}
							>
								Waitlist
							</SidebarButton>
						</Stack>
					):
					<Center width='100%' height='180px'>
						<Text>No shift started</Text>
					</Center>}
					<Divider />
					<SidebarButton
						rightIcon={<i className="fa-solid fa-comments"></i>}
						to={`/groups/${group.name}/shifts`}
						as={Link}
					>
						Shift
					</SidebarButton>
					{ group && me && (group.owner._id === me._id || group.admins.find(admin => admin._id === me._id)) &&
						<>
						<SidebarButton
							rightIcon={<i className="fa-solid fa-users"></i>}
							to={`/users`}
							as={Link}
						>
							Users
						</SidebarButton>
						<SidebarButton
							rightIcon={<i className="fa-solid fa-utensils"></i>}
							to={`/groups/${group.name}/products`}
							as={Link}
						>
							Products
						</SidebarButton>
						{me.type === 'user' && <SidebarButton
							rightIcon={<i className="fa-solid fa-plug"></i>}
							to={`/groups/${group.name}/devices`}
							as={Link}
						>
							Devices
						</SidebarButton>}
						</>
					}
				</Stack>
				<SidebarButton
					rightIcon={<i className="fa-solid fa-gear"></i>}
					to={`/groups/${group.name}/settings`}
					as={Link}
				>
					Settings
				</SidebarButton>
			</DrawerBody>
			<SidebarFooter />
			</DrawerContent>
		</Drawer>
	);
}