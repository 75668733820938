import { Button, Flex, FormControl, FormHelperText, FormLabel, Heading, Input, Stack, Switch } from '@chakra-ui/react';
import AdminTemplate from '../../templates/AdminTemplate';
import { useContext, useEffect, useState } from 'react';
import { GroupContext } from '../../context/GroupContext';
import { UserContext } from '../../context/UserContext';
import SettingsGeneral from '../../components/Settings/SettingsGeneral';

export default function Settings() {

	const {group, setDiscordChannel} = useContext(GroupContext);
	const { me } = useContext(UserContext);

	const [discordChannelID, setDiscordChannelID] = useState("");
	const [discordChannelIDState, setDiscordChannelIDState] = useState(false);
	const [discordChannelIDActive, setDiscordChannelIDActive] = useState(false);

	useEffect(() => {
		if (group)
		{
			setDiscordChannelID(group.discordChannelID ? group.discordChannelID : "");
			if (group.discordChannelID)
				setDiscordChannelIDActive(true);
		}
	}, [group]);

	const updateDiscordChannelID = async (channelID) => {
		setDiscordChannelIDState(true);
		setDiscordChannel(channelID);
		setDiscordChannelIDState(false);
	};

	const handleDiscordChannelIDChecked = (e) => {
		setDiscordChannelIDActive(e.target.checked);
		if (!e.target.checked && group.discordChannelID)
			setDiscordChannel("")
	}

	return (
		<AdminTemplate width='100vw'>
			<Flex
				width='100vw'
				flexDir='column'
				alignItems='center'
				overflowY='scroll'
				pb='10vh'
			>
				<Heading marginY='16px'>Settings</Heading>
	
				<SettingsGeneral
					SAFI
					marginY='24px'
					width='90%'
				/>

				{ group && me && (group.owner._id === me._id || group.admins.find(admin => admin._id === me._id)) &&
					<Stack
						width='90%'
						marginY='24px'
					>
						<Heading size='md' marginY='16px'>Group</Heading>

						<FormControl
							marginY='16px'
						>
							<Flex
								alignItems='center'
								justifyContent='space-between'
							>
								<FormLabel>Discord Channel ID</FormLabel>
								<Switch isChecked={discordChannelIDActive} onChange={handleDiscordChannelIDChecked} />
							</Flex>
							{discordChannelIDActive && <Flex
								alignItems='center'
								justifyContent='space-between'
							>
								<Input
									width='80%'
									value={discordChannelID}
									onChange={(e) => {setDiscordChannelID(e.target.value)}}
								/>
								<Button
									colorScheme='blue'
									ml='8px'
									leftIcon={<i className="fa-solid fa-floppy-disk"></i>}
									isDisabled={discordChannelID === group.discordChannelID || discordChannelID.length <= 0}
									isLoading={discordChannelIDState}
									onClick={() => updateDiscordChannelID(discordChannelID)}
								>
									Save
								</Button>
							</Flex>}
							<FormHelperText>
								Discord Channel ID is used to send notifications when orders are ended
						</FormHelperText>
						</FormControl>


					</Stack>
				}
			</Flex>
		</AdminTemplate>
	);
}
