import { useContext, useEffect, useState } from 'react';
import config from '../config';
import { GroupContext } from '../context/GroupContext';
import useAlert from './useAlert';

export default function useDevices()
{
	
	const { alertError, alertSuccess } = useAlert();
	const { group } = useContext(GroupContext);
	const [devices, setDevices] = useState([]);

	useEffect(() => {
		if (group) {
			fetchDevices();
		}
		// eslint-disable-next-line
	}, [group]);

	const fetchDevices = async () => {
		const response = await fetch(`${config.apiUrl}/api/groups/${group.name}/devices`, {
			method: 'GET',
			credentials: 'include',
		});
		if (response.status === 200) {
			const data = await response.json();
			setDevices(data);
		}
		else {
			alertError('error', 'Could not fetch devices');
		}
		return response;
	}

	const createDevice = async (name, permission) => {
		const response = await fetch(`${config.apiUrl}/api/groups/${group.name}/devices`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({name: name, permission: permission}),
			credentials: 'include',
		});
		if (response.status === 200) {
			const data = await response.json();
			alertSuccess(`Device ${data.name} created`);
			setDevices([...devices, data]);
		}
		else {
			alertError('error', 'Could not create device');
		}
		return response;
	}

	const deleteDevice = async (device) => {
		const response = await fetch(`${config.apiUrl}/api/groups/${group.name}/devices/${device._id}`, {
			method: 'DELETE',
			credentials: 'include',
		});
		if (response.status === 200) {
			alertSuccess(`Device ${device.name} deleted`);
			setDevices([...devices.filter(d => d._id !== device._id)]);
		}
		else {
			alertError('error', 'Could not delete device');
		}
		return response;
	}

	const refreshToken = async (device) => {
		const response = await fetch(`${config.apiUrl}/api/groups/${group.name}/devices/${device._id}/token`, {
			credentials: 'include',
		});
		if (response.status === 200) {
			alertSuccess(`Device ${device.name} token refreshed`);
			const data = await response.json();
			setDevices([...devices.map(d => d._id === device._id ? data : d)]);
		}
		else {
			alertError('error', 'Could not refresh device token');
		}
		return response
	}

	return {
		devices: devices.map(d => ({
			delete: () => deleteDevice(d),
			refreshToken: () => refreshToken(d),
			...d
		})),
		create: createDevice,
	};
}
