import { Flex } from '@chakra-ui/react';
import AdminTemplate from '../../templates/AdminTemplate';
import ShiftList from '../../components/ShiftList/ShiftList';
import ShiftCardCreate from '../../components/ShiftCard/ShiftCardCreate';
import useShifts from '../../hooks/useShifts';



export default function Shift() {

	const {shifts, createShift, deleteShift, updateShift} = useShifts();

	return (
		<AdminTemplate width='100vw'>
			<Flex
				width='100vw'
				flexDir='column'
				alignItems='center'
				overflowY='scroll'
			>
				<ShiftCardCreate createShift={createShift} width='90vw' marginY='16px'/>
				<ShiftList shifts={shifts} updateShift={updateShift} deleteShift={deleteShift} />
			</Flex>
		</AdminTemplate>
	);
}
