import { Center, Heading, SimpleGrid, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import OrderCard from "../OrderCard/OrderCard";
import useUserOrder from "../../hooks/useUserOrder";


export default function OrderList({...props})
{

	const {orders} = useUserOrder();

	const bg = useColorModeValue('gray.200', 'gray.600');

	if (orders.length !== 0)
		return (
			<Stack
				width='100%'
				bg={bg}
				alignItems='center'
				height='100%'
				{...props}
				overflowY='scroll'
				pb='16px'
			>
				<Heading size='md' alignSelf='flex-start' ml='16px' my='24px'>My orders</Heading>
				<SimpleGrid
					columns={[1, 1, 2, 3, 3, 4]}
					spacing={8}
					width='90%'
					overflowY='scroll'
				>
					{orders.map((order) => (
						<OrderCard
							order={order}
							key={order._id}
						/>
					))}
				</SimpleGrid>
			</Stack>
		);
	return (
		<Center
			width='100%' bg={bg}
			height='100%'
			{...props}
		>
			<Text>
				No order yet
			</Text>
		</Center>
	)

}