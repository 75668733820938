import { useEffect, useState } from "react";


export default function useMenu(menu = {
	name: '',
	price: 0,
	imageUrl: null,
	categories: [{
		name: '',
		products: []
	}]
}) {

	const [name, setName] = useState(menu.name);
	const [price, setPrice] = useState(menu.price);
	const [imageUrl, setImageUrl] = useState(menu.imageUrl);
	const [categories, setCategories] = useState(menu.categories);
	const [isValid, setIsValid] = useState(false);

	useEffect(() => {
		if (name && name.length > 0
			&& price >= 0 && price.toString().length > 0
			&& categories.length > 0
			&& categories.every(p => p.name.length > 0 && p.products.length > 0)
		)
			setIsValid(true);
		else
			setIsValid(false);
	}, [name, price, imageUrl, categories])


	const addCategory = () => {
		setCategories([...categories, {
			name: '',
			products: []
		}]);
	
	}

	const removeCategory = (index) => {
		if (index >= 0) {
			const newProducts = [...categories];
			newProducts.splice(index, 1);
			setCategories(newProducts);
		}
	}

	const handleCategoryName = (index, name) => {
		if (index >= 0) {
			const newProducts = [...categories];
			newProducts[index].name = name;
			setCategories(newProducts);
		}
	}

	const addCategoryProduct = (index, product) => {
		if (index >= 0) {
			const newProducts = [...categories];
			newProducts[index].products = [...newProducts[index].products, product];
			setCategories(newProducts);
		}
	
	}

	const handleCategoryProduct = (index, productIndex, product) => {
		if (index >= 0) {
			const newProducts = [...categories];
			(newProducts[index].products)[productIndex] = product;
			setCategories(newProducts);
		}
	}

	const removeCategoryProduct = (index, productIndex) => {
		if (index >= 0 && productIndex >= 0) {
			const newProducts = [...categories];
			newProducts[index].products.splice(productIndex, 1);
			setCategories(newProducts);
		}
	}

	return ({
		menu: {
			_id: menu._id,
			name,
			price,
			imageUrl,
			categories
		},
		isValid,
		edit: {
			setName,
			setPrice,
			setImageUrl,
			addCategory,
			removeCategory,
			handleCategoryName,
			handleCategoryProduct,
			removeCategoryProduct,
			addCategoryProduct
		}
	})
}
	

