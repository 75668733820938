import { AgGridReact } from 'ag-grid-react';
import UserTableProfileCell from './UserTableProfileCell';
import { Box, useColorMode } from '@chakra-ui/react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useState, useEffect } from 'react';

import UserTableGroups from './UserTableGroups';
import { useNavigate } from 'react-router-dom';

export default function UserTable({users, ...props})
{

	const [rowData, setRowData] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		var userss = users.map((user) => (
			{
				Login : {
					login: user.login,
					src: user.intraUser ? user.intraUser.imageLink : '/defaultUser.jpg',
					admin: user.isSuperAdmin ? 2 : 0
				},
				Groups: {groups: user.groups, userId: user._id},
			}
		))
		setRowData(userss)
	}, [users])

	const [colDefs] = useState([
		{ field: "Login",
			cellRenderer: UserTableProfileCell,
			cellDataType: false,
			suppressMovable: true,
			flex: 1,
		},
		{ field: "Groups",
			cellRenderer: UserTableGroups,
			cellDataType: false,
			suppressMovable: true,
			flex: 1,
		},
	]);

	const onRowClicked = (event) => {
		const userId = event.data.Login.login;
		navigate(`/users/${userId}`);
	};

	const { colorMode } = useColorMode();

	return (
		<Box {...props} className={colorMode === 'light' ? "ag-theme-quartz" : "ag-theme-quartz-dark"} width='90vw' overflow='hidden'>
			<AgGridReact
				rowData={rowData}
				columnDefs={colDefs}
				onRowClicked={onRowClicked}
			/>
		</Box>
	);

}