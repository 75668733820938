import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, IconButton, Text, useDisclosure } from "@chakra-ui/react";
import OrderItemMenu from "../OrderItem/OrderItemMenu";
import OrderItemProduct from "../OrderItem/OrderItemProduct";
import CheckoutModal from "../CheckoutModal/CheckoutModal";
import { useRef } from "react";


export default function CartDrawer({isOpen, onClose, cart, ...props})
{

	const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure();
	const initialRef = useRef(null);

	return (
		<Drawer
			isOpen={isOpen}
			placement='right'
			isFullHeight
			onClose={onClose}
			height="100dvh"
			{...props}
		>
			<DrawerOverlay />
			<DrawerContent
				height="100dvh"
			>
				<DrawerCloseButton />
				<DrawerHeader
					borderBottomWidth='1px'
					display='flex'
					alignItems='center'
				>
						<Text opacity='1' marginX='8px'>
							Cart
						</Text>
				</DrawerHeader>
				<DrawerBody display='flex' flexDir='column' maxHeight='80%' paddingInline={0} alignItems='center'>
					{cart.items.map((item, index) => (
					<Flex
						key={index}
						alignItems='center'
						width='90%'
					>
						{item.subItems && item.subItems.length > 0 ?
							<OrderItemMenu item={item} />
							:
							<OrderItemProduct item={item} />
						}
						<Flex
							alignItems='center'
						>
							<Text>{item.quantity * item.product.price}€</Text>
							<IconButton
								ml='8px'
								icon={<i className="fa-solid fa-trash"></i>}
								onClick={() => cart.removeItem(index)}
								colorScheme="red"	
							/>
						</Flex>
					</Flex>))}
				</DrawerBody>
				<DrawerFooter
					borderTopWidth='1px'
					flexDir='column'
					display='flex'
					alignItems='flex-start'
				>
					<Text>Total {cart.items.map((item) => item.product.price * item.quantity).reduce((a, b) => a + b, 0)}€</Text>
					<Flex
						marginTop='16px'
						width='100%'
						alignItems='center'
						justifyContent='space-around'
					>
						<Button
							colorScheme="gray"
							rightIcon={<i className="fa-solid fa-arrow-right"></i>}
							onClick={onClose}
						>
							Close
						</Button> 
						<Button
							ml='8px'
							colorScheme='green'
							leftIcon={<i className="fa-solid fa-cart-shopping"></i>}
							onClick={onOpenModal}
							isDisabled={cart.items.length === 0}
						>
							Confirm
						</Button>
					</Flex>
				</DrawerFooter>
			</DrawerContent>
			<CheckoutModal
				cart={cart}
				isOpen={isOpenModal}
				onClose={onCloseModal}
				initialRef={initialRef}
			/>
		</Drawer>
	);

}