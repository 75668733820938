import { useToast } from '@chakra-ui/react'

export default function useAlert()
{
	const toast = useToast()
	const alertError = (message) => {
		toast({
			title: 'Error',
			description: message,
			status: 'error',
			duration: 5000,
			isClosable: true,
		})
	}
	const alertSuccess = (message) => {
		toast({
			title: 'Success',
			description: message,
			status: 'success',
			duration: 5000,
			isClosable: true,
		})
	}
	const alertWarning = (message) => {
		toast({
			title: 'Warning',
			description: message,
			status: 'warning',
			duration: 5000,
			isClosable: true,
		})
	}
	return { alertError, alertSuccess, alertWarning }
}
