import { List } from "@chakra-ui/react";
import OrderItemProduct from "./OrderItemProduct";
import OrderItemMenu from "./OrderItemMenu";


export default function OrderItemList({order, ...props})
{
	return (
		<List
			{...props}
		>
			{order.items.map(item => (
				item.subItems.length === 0 ?
				<OrderItemProduct key={item._id} item={item} /> :
				<OrderItemMenu key={item._id} item={item} />
			))}
		</List>
	);

}