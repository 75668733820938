import { Button, Text, Image, Center, useColorMode } from '@chakra-ui/react';
import config from '../config';
import { useContext, useEffect } from 'react';
import { UserContext } from '../context/UserContext';

export default function Login() {

	const {me} = useContext(UserContext)

	useEffect(() => {
		if (me)
			window.location.replace('/')
	}, [me])

	const { colorMode } = useColorMode();

	return (
		<Center width='100vw' height='100vh' justifyContent='space-around' flexDir='column'>
			<Image src={colorMode === 'light' ? '/logo_light.png' : '/logo_dark.png'} height='20vh'/>
			<Button
				as='a'
				href={`${config.apiUrl}/oauth/auth-code`}
				bg='black'
				color='white'
			>
				<Text>Login with </Text>
				<Image marginInline='8px' height='16px' src='https://profile.intra.42.fr/assets/42_logo-7dfc9110a5319a308863b96bda33cea995046d1731cebb735e41b16255106c12.svg' />
				<Text>intra</Text>
			</Button>
		</Center>
	);
}
