import { Card, Heading, Image, Stack, Tag, Text, useDisclosure } from "@chakra-ui/react";
import CheckoutProductDrawer from "./CheckoutProductDrawer";
import CheckoutMenuDrawer from "./CheckoutMenuDrawer";


export default function CheckoutItem({item, cart, ...props})
{
	const {isOpen, onOpen, onClose} = useDisclosure();
	const {isOpen: menuDrawerIsOpen, onOpen: menuDrawerOnOpen, onClose: menuDrawerOnClose} = useDisclosure();

	const onAddItem = () => {
		if ((item.details && item.details.length === 0) && (item.categories && item.categories.length === 0)){
			cart.addItem({product: item, quantity: 1});
		}
		else if ((item.details && item.details.length > 0) && (item.categories && item.categories.length === 0)) {
			onOpen()
		}
		else if ((item.details && item.details.length === 0) && (item.categories && item.categories.length > 0)) {
			menuDrawerOnOpen();
		}
	}

		return (
		<Card
			{...props}
			display='flex'
			alignItems='center'
			justifyContent='center'
			overflow='hidden'
			onClick={onAddItem}
		>
			<Image
				objectFit='cover'
				src={item.imageUrl ? item.imageUrl : 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/HD_transparent_picture.png/1280px-HD_transparent_picture.png'}
				alt={item.name}
			/>
			<Stack
				pos='absolute'
				width='100%'
				height='100%'
				padding='16px'
				justifyContent='space-between'
			>
				<Heading size='sm'>{item.name}</Heading>
				<Text>{item.price}€</Text>
				{cart.items.find(cartItem => cartItem.product._id === item._id) &&
					<Tag
						position={'absolute'}
						top={'16px'}
						right={'16px'}
					>
						{cart.items.filter(cartItem => cartItem.product._id === item._id).map(cartItem => cartItem.quantity).reduce((a, b) => a + b, 0)}
					</Tag>
				}
			</Stack>
			<CheckoutProductDrawer item={item} isOpen={isOpen} onClose={onClose} cart={cart}/>
			<CheckoutMenuDrawer item={item} isOpen={menuDrawerIsOpen} onClose={menuDrawerOnClose} cart={cart}/>
		</Card>
	);

}