import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Heading, Stack, Tag, Text } from "@chakra-ui/react";
import CheckoutItemQuantity from "./CheckoutItemQuantity";
import { useState } from "react";


export default function CheckoutProductDrawer({item, isOpen, onClose, cart, ...props})
{

	const [quantity, setQuantity] = useState(1);
	const [details, setDetails] = useState([]);

	const onToggleDetail = (detail) => {
		const index = details.indexOf(detail);
		if (index === -1)
			setDetails([...details, detail]);
		else
			setDetails(details.filter(d => d !== detail));
	}

	const onAddProduct = () => {
		cart.addItem({
			product: item,
			quantity,
			details
		});
		onClose();
		setDetails([]);
		setQuantity(1);
	}

	return (
		<Drawer
			isOpen={isOpen}
			placement='bottom'
			onClose={onClose}
		>
			<DrawerOverlay />
			<DrawerContent height='80%'>
				<DrawerCloseButton />
				<DrawerHeader borderBottomWidth='1px'>
					<Flex
						alignItems='center'
					>

						<Text opacity='1' marginX='8px'>
							{item.name}
						</Text>
						<CheckoutItemQuantity
							marginLeft='32px'
							quantity={quantity}
							setQuantity={setQuantity}
						/>
					</Flex>
				</DrawerHeader>
				<DrawerBody display='flex' flexDir='column' maxHeight='80%' overflowY='scroll'>
					<Stack width='100%'>
						<Heading size='md'>Details</Heading>
						<Stack
							width='100%'
							alignItems='center'
						>
							{item.details.map((detail, index) => (
								<Tag
									padding='16px'
									display='flex'
									justifyContent='center'
									key={index}
									onClick={() => onToggleDetail(detail)}
									colorScheme={details.includes(detail) ? 'green' : 'gray'}
									width='90%'
									marginY='8px'
								>
									{detail}
								</Tag>
							))}
						</Stack>
					</Stack>
				</DrawerBody>
				<DrawerFooter borderTopWidth='1px'>
					<Button
						marginX='8px'
						variant='outline'
						onClick={onClose}
					>
						Cancel
					</Button>
					<Button
						colorScheme='green'
						leftIcon={<i className="fa-solid fa-cart-shopping"></i>}
						onClick={onAddProduct}
					>
						Add to cart
					</Button> 
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);

}