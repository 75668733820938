import { Center, Flex, Heading, List, Text } from '@chakra-ui/react';
import ShiftCardEdit from '../ShiftCard/ShiftCardEdit';
import ShiftCardArchived from '../ShiftCard/ShiftCardArchived';



export default function ShiftList({shifts, updateShift, deleteShift, ...props}) {

	return (
		<Flex
			flexDir='column'
			width='100%'
			alignItems='center'
			marginY='16px'
			{...props}
		>
			<Heading
				marginLeft='12px'
				width='90%'
				marginY='8px'
			>
				Shifts
			</Heading>
			<List width='90%'
				marginBottom='16px'
			>
				{shifts.filter((shift) => shift.state !== 2).length === 0 &&
					<Center width='100%' height='10vh'>
						<Text>No active shifts</Text>
					</Center>
				}
				{shifts.filter((shift) => shift.state !== 2).map((shift) => (
					<ShiftCardEdit
						key={shift._id}
						defaultShift={shift}
						updateShift={updateShift}
						deleteShift={deleteShift}
					/>
				))}
			</List>
			<Flex
				marginY='8px'
				marginLeft='12px'
				width='90%'
				justifyContent='space-between'
				alignItems='center'
			>
				<Heading>
					History
				</Heading>
				<Text>
					{shifts.filter((shift) => shift.state === 2).length} shifts
				</Text>
			</Flex>
			<List
				width='90%'
			>
				{shifts.filter((shift) => shift.state === 2).length === 0 &&
					<Center width='100%' height='10vh'>
						<Text>No archived shifts</Text>
					</Center>
				}
				{shifts.filter((shift) => shift.state === 2).map((shift) => (
					<ShiftCardArchived
						key={shift._id}
						shift={shift}
					/>
				))}
			</List>
		</Flex>
	);
}
