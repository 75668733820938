import { Badge, Center, Flex, Heading, Text } from "@chakra-ui/react";


export default function OrderItemProduct({item, ...props})
{
	return (
			<Flex
				marginY='8px'
				width='100%'
				alignItems='center'
				{...props}
			>
				<Badge
					width='32px'
					height='32px'
					fontSize='18px'
					colorScheme='gray'
				>
					<Center>
						{item.quantity}
					</Center>
				</Badge>
				<Flex
					width='100%'
					flexDir='column'
					marginX='12px'
				>
					<Heading
						size='sm'
					>
						{item.product.name}
					</Heading>
					<Flex
						maxWidth='90%'
						flexWrap='wrap'
					>
						{item.details && item.details.map((detail, index) => (
							<Text
								mr='8px'
								key={index}
							>
								{detail}
								{index < item.details.length - 1 && ','}
							</Text>
						))}
					</Flex>
				</Flex>
			</Flex>
	);

}
