import { useState, useEffect } from 'react'
import config from '../config';
import { useSearchParams } from 'react-router-dom';

export default function useUsers() {

	const [searchParams, setSearchParams] = useSearchParams();
	const [users, setUsers] = useState([]);
	const [search, setSearch] = useState(searchParams.get('search') ? searchParams.get('search') : '');
	const [page, setPage] = useState(searchParams.get('page') ? parseInt(searchParams.get('page')) : 1);
	const [total, setTotal] = useState(0);

	const getUsers = async (currentPage, currentLogin) => {
		fetch(`${config.apiUrl}/api/users?login=${currentLogin}&offset=${(currentPage - 1) * 10}`, {
			credentials: 'include',
		})
		.then(async (res) => {
			if (res.ok) {
				setTotal(res.headers.get('X-Total-Count'));
				const	r = await res.json();
				setUsers(r);
			}
		})
	}

	useEffect(() => {
		getUsers(page, search)
	}, [page, search])

	const onSearch = async (login) => {
		setPage(1);
		setSearch(login);
		const newParams = new URLSearchParams(searchParams);
    	newParams.set('search', login);
		newParams.set('page', 1);
		setSearchParams(newParams, false);
	}

	const pageUp = async () => {
		if (page * 10 < total + 10) {
			setPage(page + 1);
			const newParams = new URLSearchParams(searchParams);
			newParams.set('page', page + 1);
			setSearchParams(newParams, false);
		}
	}

	const pageDown = async () => {
		if (page * 10 - 10 > 1) {
			setPage(page - 1);
			const newParams = new URLSearchParams(searchParams);
			newParams.set('page', page - 1);
			setSearchParams(newParams, false);
		}
	}

	return {
		users,
		search, onSearch,
		page, pageUp, pageDown,
		total
	}
}
