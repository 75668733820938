import {
	Accordion,
	Center,
	Heading,
	Stack,
	Text,
  } from '@chakra-ui/react'
import DeviceListItem from './DeviceListItem';

export default function DeviceList({devices, ...props}) {

		return (
			<Stack
				width='100%'
			>
				<Heading
					marginLeft='16px'
				>
					Device List
				</Heading>
				<Accordion
					width='100%'
				>
					{
						devices.map((device, index) => {
							return <DeviceListItem key={index} device={device}/>
						})
					}
				</Accordion>
				{ devices.length === 0 &&
					<Center
						width='100%'
						height='20vh'
					>
						<Text>No devices</Text>
					</Center> }
			</Stack>
		);
}
