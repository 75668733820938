import "./App.css";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";

import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import theme from "./theme";

import Orders from "./pages/admin/Orders";
import Checkout from "./pages/admin/Checkout";
import Users from "./pages/admin/Users";
import User from "./pages/admin/User";
import Shift from "./pages/admin/Shifts";
import Products from "./pages/admin/Products";
import Settings from "./pages/admin/Settings";
import AdminHome from "./pages/admin/Home";
import Login from "./pages/Login";
import { GroupProvider } from "./context/GroupContext";
import { UserProvider } from "./context/UserContext";
import Waitlist from "./pages/admin/Waitlist";
import Profile from "./pages/Profile";
import ProfileSettings from './pages/Settings'
import Devices from "./pages/admin/Devices";

function App() {
    return (
        <UserProvider>
        <ChakraProvider theme={theme}>
            <ColorModeScript initialColorMode={theme.config.initialColorMode} />
            <Router>
                    <Routes>
                        <Route
                            path="users"
                            element={<GroupProvider><Users /></GroupProvider>}
                        />
                        <Route
                            path="/users/:userId"
                            element={<GroupProvider><User /></GroupProvider>}
                        />
                        <Route
                            path="/groups/:groupId/*"
                            element={<GroupsRouter />}
                        />
                        <Route
                            path="/"
                            element={<Profile />}
                        />
                        <Route
                            path="/settings"
                            element={<ProfileSettings />}
                        />
                        <Route
                            path="/login"
                            element={<Login />}
                        />
                    </Routes>
                </Router>
        </ChakraProvider>
        </UserProvider>
    );
}

function GroupsRouter() {
    return (
            <GroupProvider>
                <Routes>
                    <Route
                        path="shifts/:shiftId/*"
                        element={<ShiftRouter />}
                    />
                    <Route
                        path="shifts"
                        element={<Shift />}
                    />
                    <Route
                        path="products"
                        element={<Products />}
                    />
                    <Route
                        path="settings"
                        element={<Settings />}
                    />
                    <Route
                        path="devices"
                        element={<Devices />}
                    />
                    <Route
                        path="*"
                        element={<AdminHome />}
                    />
                </Routes>
            </GroupProvider>
    );
}


function    ShiftRouter(){
    return(
        <GroupProvider>
        <Routes>
            <Route
                path="orders"
                element={<Orders />}
            />
            <Route
                path="checkout"
                element={<Checkout />}
            />
            <Route
                path="waitlist"
                element={<Waitlist />}
            />
        </Routes>
        </GroupProvider>
    )
}

export default App;