import {
	Modal,
	ModalBody,
	ModalContent,
	ModalCloseButton,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Button,
	Input,
	FormControl,
	FormLabel,
	Box,
} from '@chakra-ui/react';
import CheckoutModalUserCard from './CheckoutModalUserCard';
import useUsers from '../../hooks/useUsers';
import { useEffect, useState } from 'react';

export default function CheckoutModal({isOpen, onClose, initialRef, cart}) {

	const { users , search, onSearch } = useUsers();
	const [waiting, setWaiting] = useState(false);

	const postOrder = async () => {
		setWaiting(true);
		const res = await cart.sendOrder();
		if (res.ok)
			onClose()
		setWaiting(false);
	}

	useEffect(() => {
		cart.handleNameChange(search);
		// eslint-disable-next-line
	}, [users]);

	return (
	<Modal
        isOpen={isOpen}
        onClose={onClose}
		closeOnOverlayClick={false}
		initialFocusRef={initialRef}
      >
        <ModalOverlay />
        <ModalContent>
			<ModalHeader>Confirm the order</ModalHeader>
			<ModalCloseButton />
			<ModalBody pb={6}>
				<FormControl>
					<FormLabel>Login</FormLabel>
					<Input
						ref={initialRef}
						placeholder='Login'
						value={search}
						onChange={(e) => onSearch(e.target.value)}
						min={3} max={10}
					/>
					<Box height="25vh" overflowY='scroll'>
						{
							users.map((user) => (
								<CheckoutModalUserCard user={user} setUser={onSearch} key={user._id}/>
							))
						}
					</Box>
				</FormControl>
			</ModalBody>
			<ModalFooter>
				<Button
					colorScheme='blue'
					mr={3}
					leftIcon={<i className="fa-brands fa-cc-visa"></i>}
					as='a'
					target='_blank'
					href={`sumupmerchant://pay/1.0/?amount=${
						cart.items.map((item) => item.product.price * item.quantity).reduce((a, b) => a + b, 0)
						}&currency=EUR&affiliate-key=sup_pk_WuKRGXj2JjN89TZMW2MIG8JH4lh5FZIiZ`}
				>
					SumUp
				</Button>
				<Button
					colorScheme='green'
					mr={3}
					leftIcon={<i className="fa-solid fa-check"></i>}
					onClick={postOrder}
					isLoading={waiting}
					loadingText='Submitting'
					isDisabled={search.length < 1}
				>
					Create
				</Button>
				<Button onClick={onClose}>Cancel</Button>
			</ModalFooter>
		</ModalContent>
    </Modal>
	);
}