import React, { createContext, useEffect, useState } from 'react';
import config from '../config';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {

	
	const [me, setMe] = useState()
	
	useEffect(() => {
		fetch(`${config.apiUrl}/api/me`, {
			credentials: 'include',
			mode: "cors"
		})
		.then(async (res) => {
			if (res.ok) {
				const resMe = await res.json()
				setMe(resMe);
			}
		});
	}, [])
	
	return (
		<UserContext.Provider value={{ me }}>
			{children}
		</UserContext.Provider>
	);
};