import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";

export default function OrderItemEditMenu({onDelete, order})
{
	const onGiveUp = async () => {
		await order.patch({state: 1});
	}

	return (
		<Menu>
			<MenuButton as={Button} rightIcon={<i className="fa-solid fa-pen-to-square"></i>}>
				Edit
			</MenuButton>
			<MenuList>
				<MenuItem
					icon={<i className="fa-solid fa-user-pen"></i>}
				>
					Edit User
				</MenuItem>
				{order.state > 1 && <MenuItem
					icon={<i className="fa-solid fa-recycle"></i>}
					onClick={onGiveUp}
				>
					Give Up
				</MenuItem>}
				<MenuItem
					icon={<i className="fa-solid fa-trash-can"></i>}
					color='red.500'
					onClick={onDelete}
				>
					Delete
				</MenuItem>
			</MenuList>
		</Menu>
	);

}