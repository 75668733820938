import { Badge, Center, Flex, Heading, Stack } from "@chakra-ui/react";
import OrderItemProduct from "./OrderItemProduct";


export default function OrderItemMenu({item, ...props})
{
	return (
			<Stack
				marginY='16px'
				width='100%'
				{...props}
				gap='0'
			>
				<Flex
					width='100%'
					align='center'
				>
					<Badge
						width='32px'
						height='32px'
						fontSize='18px'
						colorScheme='gray'
					>
						<Center>
							{item.quantity}
						</Center>
					</Badge>
					<Heading
						marginInline='8px'
						size='sm'
					>
						{item.product.name}
					</Heading>
				</Flex>
				<Stack
					width='85%'
					flexWrap='wrap'
					marginLeft='4%'
					paddingLeft='5%'
					borderLeft='1px solid lightgray'
				>
					{item.subItems.map((item, index) => (
						<OrderItemProduct
							key={index}
							item={item}
						/>
					))}
				</Stack>
			</Stack>
	);

}
