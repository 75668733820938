import { HamburgerIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading, IconButton, Tag, TagLabel, useColorModeValue } from "@chakra-ui/react";
import { useContext } from "react";
import { GroupContext } from "../../context/GroupContext";


export default function Header({openMenu, icon}) {

	const { group } = useContext(GroupContext);

	const bg = useColorModeValue('gray.100', 'gray.700');

	if (group)
	return (
		<Flex
			width='100vw' top='0'
			alignItems='center'
			height='72px'
			bg={bg}
			justifyContent='space-between'
			paddingX='12px'
		>
			<IconButton
				onClick={openMenu}
				variant='ghost'
				fontSize='24px'
				icon={icon ? icon : <HamburgerIcon />}
			/>
			<Flex alignItems='center'>
				<Heading size='lg' justifySelf='center'>
					ft_waitlist
				</Heading>
				<Tag
					ml='8px'
					borderRadius='full'
					colorScheme={group.color}
				>
					<TagLabel>{group.name}</TagLabel>
				</Tag>
			</Flex>
			<Box width='40px'></Box>
		</Flex>
	);
}