import { useState, useEffect, useContext } from 'react'
import { GroupContext } from '../context/GroupContext';
import config from '../config';
import useAlert from './useAlert';
import { UserContext } from '../context/UserContext';
import useExpand from './useExpand';
import useWebSocket from 'react-use-websocket';

export default function useOrders() {

	const { group, shift } = useContext(GroupContext)
	const { alertError, alertSuccess } = useAlert();
	const { me } = useContext(UserContext);
	const [expandIndex, handleExpand, applyExpand] = useExpand();
	const WS_URL = group && shift ? `${config.wsUrl}/api/groups/${group.name}/shifts/${shift._id}/notify`: null;

	const [orders, setOrders] = useState([]);

	function sortOrder(ordersList) {
		function getRating(a)
		{
			if (a.state === 2 && a.assignedTo && me && a.assignedTo.login === me.login)
				return (6);
			if (a.state === 2)
				return (5)
			if (a.state === 1)
				return (4);
			if (a.state === 2)
				return (3);
			if (a.state === 3)
				return (2);
			if (a.state === 4)
				return (1);
			return (0);
		}
		const orderedOrders = [...ordersList].sort((a, b) =>{
			let ar = getRating(a);
			let br = getRating(b);
			if (ar === br)
				return a.orderNumber - b.orderNumber;
			return br - ar;
		})
		applyExpand(orderedOrders);
		setOrders(orderedOrders);
	}

	const fetchOrders = async () => {
		const res = await fetch(`${config.apiUrl}/api/groups/${group.name}/shifts/${shift._id}/orders`, {
			credentials: 'include',
		})
		if (res.ok) {
			const data = await res.json();
			sortOrder(data)
		}
		else {
			alertError("Failed to get orders.")
		}
	}

	useEffect(() => {
		if (group && shift) {
			fetchOrders();
		}
		// eslint-disable-next-line
	}, [group, shift])

	const deleteOrder = async (order) => {
		const res = await fetch(`${config.apiUrl}/api/groups/${group.name}/shifts/${shift._id}/orders/${order._id}`, {
			method: 'DELETE',
			credentials: 'include',
		})
		if (res.ok) {
			alertSuccess(`Order #${order.orderNumber} deleted.`)
			sortOrder([...orders.filter(o => o._id !== order._id)]);
			handleExpand([...expandIndex].filter(i => i !== orders.findIndex(o => o._id === order._id)), [...orders.filter(o => o._id !== order._id)]);
		}
		else {
			alertError("Failed to delete order.")
		}
		return res;
	}

	const patchOrder = async (order, patch) => {
		const res = await fetch(`${config.apiUrl}/api/groups/${group.name}/shifts/${shift._id}/orders/${order._id}`, {
			method: 'PATCH',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(patch)
		})
		if (res.ok) {
			const data = await res.json();
			sortOrder([...orders].map(order => {
				if (order._id === data.order._id)
					return data.order;
				else if (data.finished && order._id === data.finished.order._id)
					return data.finished.order;
				return order;
			
			}));
		}
		else {
			alertError("Failed to update order.")
		}
		return res;
	}

	useWebSocket(WS_URL, {
		onOpen: () => {
		  console.log('WebSocket connection established.');
		  fetchOrders();
		},
		onMessage: (event) =>  {
			const msg = JSON.parse(event.data);
			if (msg.type === 'new')
				sortOrder([msg.new, ...orders]);
			if (msg.type === 'delete'){
				handleExpand([...expandIndex].filter(i => i !== orders.findIndex(o => o._id === msg.delete)));
				sortOrder([...orders.filter(order => order._id !== msg.delete)]);
			}
			if (msg.type === 'patch')
				sortOrder([...orders].map(order => {
					if (order._id === msg.patch.order._id)
						return msg.patch.order;
					else if (msg.patch.finished && order._id === msg.patch.finished.order._id)
						return msg.patch.finished.order;
					return order;
				}));
	 	},
		shouldReconnect: (closeEvent) => true,
		reconnectAttempts: 20,
		reconnectInterval: 10000,
	});

	return {
		orders: orders.map(order => ({
			...order,
			delete: () => deleteOrder(order),
			patch: (patch) => patchOrder(order, patch),
		 })),
		expandIndex,
		handleExpand
	}
}
