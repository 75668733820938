import { Card, CardBody, Flex, FormControl, FormLabel, IconButton, Input } from "@chakra-ui/react";
import NewMenuProductSelect from "./NewMenuProductSelect";



export default function NewMenuCategory({deleteCategory, addProduct, handleProduct, handleName, removeProduct, category, productList}) {

	return (
	<Card variant='outline' marginY='4px'>
 		<CardBody flexDir='column' padding='8px'>
			<FormControl marginY='8px' isRequired>
				<Flex alignItems='center' justifyContent='space-between' width='100%' marginBottom='8px'>
					<FormLabel>Category Name</FormLabel>
					<IconButton
						onClick={() => deleteCategory()}
						icon={<i className="fa-solid fa-minus"></i>}
						colorScheme="red"
					/>
				</Flex>
				<Input
					value={category ? category.name : ''}
					onChange={(e) => handleName(e.target.value)}
					type='text'
				/>
			</FormControl>
			{category.products.map((product, index) => (
				<NewMenuProductSelect
					key={index}
					product={product}
					productList={productList}
					handleProduct={(p) => handleProduct(index, p)}
					removeProduct={() => removeProduct(index)}
				/>
			
			))}
			<NewMenuProductSelect
				productList={productList}
				addProduct={(p) => addProduct(p)}
			/>
		</CardBody>
	</Card>
	);
}