import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button
} from '@chakra-ui/react'
import { useState } from 'react';

export default function DeviceDeleteModal({isOpen, onClose, device}) {

	const [loading, setLoading] = useState(false);

	const deleteDevice = async () => {
		setLoading(true);
		const response = await device.delete();
		if (response.status === 200) {
			onClose();
		}
		setLoading(false);
	}

	return (
		<Modal isCentered isOpen={isOpen} onClose={onClose}>
			<ModalOverlay
				bg='blackAlpha.300'
				backdropFilter='blur(10px)'
			/>
			<ModalContent>
				<ModalHeader>Delete {device.name}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					Are you sure you want to delete {device.name} ?
				</ModalBody>
				<ModalFooter>
					<Button
						leftIcon={<i className="fa-solid fa-trash"></i>}
						colorScheme='red' mr={3}
						onClick={() => deleteDevice()}
						isLoading={loading}
					>
						Delete
					</Button>
					<Button onClick={onClose}>Cancel</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}