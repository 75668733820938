import { Flex, IconButton, Input } from "@chakra-ui/react";

export default function UserRoles({user, me, ...props}) {

	const isAdmin = (group) => {
		return (me.isSuperAdmin || group.owner === me._id ||  group.admins.find(admin => admin === me._id))
	}

	return (
		<Flex
			{...props}
			flexDir='column'
		>
			{me.groups.filter(group => isAdmin(group) && group.owner !== user._id).map(group => (
				<Flex
					key={group._id}
					width='100%'
					alignItems='center'
					marginY='8px'
				>
					{ user && <Buttons user={user} me={me} oldGroup={group} />}
				</Flex>
			))}
		</Flex>
	);
}

function Buttons ({user, me ,oldGroup}) {
	const group = user.groups.find(userGroup => userGroup._id === oldGroup._id)
	if (group)
		return (
		<>
			<Input
				value={group.name}
				name={group.name}
				variant='filled'
				disabled
				isReadOnly
			/>
			{(group.owner === me._id || me.isSuperAdmin) && !group.admins.includes(user._id) &&
				<IconButton
				colorScheme="green"
				icon={<i className="fa-solid fa-star"></i>}
				marginLeft='8px'
				onClick={() => user.addAdminGroup(group.name)}
				/>
			}
			{(group.owner === me._id || me.isSuperAdmin) && group.admins.includes(user._id) &&
				<IconButton
				colorScheme="red"
				icon={<i className="fa-solid fa-star"></i>}
				marginLeft='8px'
				onClick={() => user.removeAdminGroup(group.name)}
				/>
			}
			{group.owner !== user._id && !group.admins.includes(user._id) &&
				<IconButton
					colorScheme="red"
					icon={<i className="fa-solid fa-minus"></i>}
					marginLeft='8px'
					onClick={() => user.removeGroup(group.name)}
				/>
			}
		</>)
	else
		return(
	<>
		<Input
			value={oldGroup.name}
			name={oldGroup.name}
			variant='filled'
			isReadOnly
		/>
		<IconButton
			colorScheme="green"
			icon={<i className="fa-solid fa-plus"></i>}
			marginLeft='8px'
			onClick={() => user.addGroup(oldGroup.name)}
		/>
	</>
	)
}