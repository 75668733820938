import { Button, DrawerFooter } from "@chakra-ui/react";
import { useState } from "react";


export default function OrderItemDrawerFooter({onClose, order})
{

	const[isWaiting, setIsWaiting] = useState(false);

	const setAsFinish = async () => {
		setIsWaiting(true);
		const res = await order.patch({state: 3});
		if (res.ok) {
			setIsWaiting(false);
		}
		onClose();
	}

	return (
		<DrawerFooter borderTopWidth='1px'>
			<Button
				marginX='8px'
				variant='outline'
				onClick={onClose}
			>
				Cancel
			</Button>
			<Button
				colorScheme='green'
				leftIcon={<i className="fa-solid fa-check"></i>}
				isLoading={isWaiting}
				onClick={setAsFinish}
			>
				Set as finish
			</Button> 
		</DrawerFooter>
	);

}