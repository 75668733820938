import { useEffect, useState } from "react";
import useAlert from "./useAlert";
import config from "../config";
import { useParams } from "react-router-dom";

export default function useUser() {

	const [user, setUser] = useState(null);
	const { userId } = useParams();

	const { alertError } = useAlert();

	const fetchUser = async (userId) => {
		const response = await fetch(`${config.apiUrl}/api/users/${userId}`, {
			credentials: 'include',
		});
		if (response.ok) {
			const data = await response.json();
			setUser(data);
		} else {
			alertError(`Failed to fetch user ${userId}`);
			setUser(undefined);
		}
	}

	useEffect(() => {
		if (userId && !user)
			fetchUser(userId)
		// eslint-disable-next-line
	}, [userId]);

	const deleteUser = async (userId) => {
		const response = await fetch(`${config.apiUrl}/api/users/${userId}`, {
			method: 'DELETE',
			credentials: 'include',
		});
		if (response.ok) {
			setUser(undefined);
			window.location.replace('/users');
		} else {
			alertError(`Failed to delete user ${userId}`);
		}
	}

	const refreshUser = async (userId) => {
		const response = await fetch(`${config.apiUrl}/api/users/${userId}/intraRefresh`, {
			credentials: 'include',
		});
		if (response.ok) {
			const data = await response.json();
			setUser(data);
		} else {
			alertError(`Failed to refresh user ${userId}`);
		}
	}

	const addUserGroup = async (userId, groupId) => {
		const response = await fetch(`${config.apiUrl}/api/groups/${groupId}/members`, {
			method: 'PUT',
			credentials: 'include',
			body: JSON.stringify({ user: userId }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
		if (response.ok) {
			const data = await response.json();
			setUser(data);
		} else {
			alertError(`Failed to add group ${groupId} to user ${userId}`);
		}
	}

	const removeUserGroup = async (userId, groupId) => {
		const response = await fetch(`${config.apiUrl}/api/groups/${groupId}/members`, {
			method: 'DELETE',
			credentials: 'include',
			body: JSON.stringify({ user: userId }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
		if (response.ok) {
			const data = await response.json();
			setUser(data);
		} else {
			alertError(`Failed to remove group ${groupId} from user ${userId}`);
		}
	}

	const addAdminGroup = async (userId, groupId) => {
		const response = await fetch(`${config.apiUrl}/api/groups/${groupId}/admins`, {
			method: 'PUT',
			credentials: 'include',
			body: JSON.stringify({ admin: userId }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
		if (response.ok) {
			const data = await response.json();
			setUser(data);
		} else {
			alertError(`Failed to add admin group ${groupId} to user ${userId}`);
		}
	
	}

	const removeAdminGroup = async (userId, groupId) => {
		const response = await fetch(`${config.apiUrl}/api/groups/${groupId}/admins`, {
			method: 'DELETE',
			credentials: 'include',
			body: JSON.stringify({ admin: userId }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
		if (response.ok) {
			const data = await response.json();
			setUser(data);
		} else {
			alertError(`Failed to remove admin group ${groupId} from user ${userId}`);
		}
	}

	if (user)
		return ({
			...user,
			delete: () => deleteUser(userId),
			refresh: () => refreshUser(userId),
			addGroup: (groupId) => addUserGroup(userId, groupId),
			removeGroup: (groupId) => removeUserGroup(userId, groupId),
			addAdminGroup: (groupId) => addAdminGroup(userId, groupId),
			removeAdminGroup: (groupId) => removeAdminGroup(userId, groupId),
		})
	else
		return undefined;
}

