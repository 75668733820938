import { Avatar, DrawerHeader, Flex, Text } from "@chakra-ui/react";


export default function OrderItemDrawerHeader({order})
{
	return (
			<DrawerHeader borderBottomWidth='1px'>
				<Flex alignItems='center'>
					<Avatar
						size='md'
						name={order.user.login}
						src={order.user.intraUser ? order.user.intraUser.imageLink : '/defaultUser.jpg'}
					/>
					<Text opacity='1' marginX='8px'>
						{order.user.login}
					</Text>
					<Text
						as='span'
						color='gray.500'
					>
						#{order.orderNumber}
					</Text>
				</Flex>
			</DrawerHeader>
	);

}