import { Flex, IconButton, Image, Text, useDisclosure } from "@chakra-ui/react";
import NewProductModal from "../NewProduct/NewProductModal";

export default function ProductListItem({product, updateProduct, deleteProduct, ...props}) {

	const { isOpen, onOpen, onClose } = useDisclosure()

	return (
		<Flex
			{...props}
			width='100%'
			alignItems='center'
			justifyContent='space-between'
			marginY='4px'
		>
			<Flex alignItems='center'>
				<Image
					boxSize='32px'
					objectFit='cover'
					borderRadius='full'
					src={product.imageUrl ? product.imageUrl : '/defaultUser.jpg'}
					alt={product.name}
				/>
				<Text marginX='8px'>{product.name} {product.price}€</Text>
			</Flex>
			<Flex>
				<IconButton mr='4px' onClick={onOpen} icon={<i className="fa-regular fa-pen-to-square"></i>}/>
				<IconButton colorScheme="red" icon={<i className="fa-solid fa-trash-can"></i>} onClick={() => (deleteProduct(product._id))}/>
			</Flex>
			<NewProductModal isOpen={isOpen} onClose={onClose} onSave={(p, id) => updateProduct(p, id)} defaultProduct={product}/>
		</Flex>
	);
}
