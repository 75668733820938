import { Flex, IconButton, Select } from "@chakra-ui/react";



export default function NewMenuProductSelect({product, productList, addProduct, handleProduct, removeProduct, ...props}) {

	return (
		<Flex marginY='8px'>
			<Select
				placeholder={addProduct ? 'Select Product' : false}
				{...props}
				onChange={addProduct ?
						(e) => {addProduct(productList.find(p => p._id ===  e.target.value)); e.target.value = '';} :
						(e) => handleProduct(productList.find(p => p._id ===  e.target.value))}
				defaultValue={product ? product._id : ''}
			>
				{productList.map((product) => (
					<option key={product._id} value={product._id}>
						{product.name} ({product.price}€)
					</option>
				))}
			</Select>
			{ product && 
				<IconButton
					marginLeft='8px'
					onClick={() => removeProduct()}
					icon={<i className="fa-solid fa-minus"></i>}
				/>
			}
		</Flex>
	);
}